import React from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";

// Schema de validação do Yup
const schema = yup.object().shape({
    percentual: yup
        .string()
        .nullable()
        .test(
            "percentual-ou-reais",
            "Preencha apenas um dos campos: Porcentagem ou Reais",
            function (value) {
                return !value || !this.parent.valorReais;
            }
        ),
    valorReais: yup
        .string()
        .nullable()
        .test(
            "percentual-ou-reais",
            "Preencha apenas um dos campos: Porcentagem ou Reais",
            function (value) {
                return !value || !this.parent.percentual;
            }
        ),
    pixHabilitado: yup.boolean(),
    percentualRotativo: yup
        .string()
        .nullable(),
});

const CorrecaoForm = ({ onSubmit, selectedCompanies }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            percentual: "",
            valorReais: "",
            pixHabilitado: false,
            percentualRotativo: "",
        },
    });

    const percentual = watch("percentual");
    const percentualRotativo = watch("percentualRotativo");
    const valorReais = watch("valorReais");

    // Formatando porcentagem
    const handlePercentualChange = (e, name) => {
        let value = e.target.value.replace(/\D/g, ""); // Remove tudo que não for número
        if (value) {
            value = `${parseFloat(value)}%`; // Adiciona o %
        }
        setValue(name, value, { shouldValidate: true });
    };

    // Formatando valor em reais
    const handleValorReaisChange = (e) => {
        let value = e.target.value.replace(/[^\d,]/g, ""); // Remove tudo que não for número ou vírgula
        if (value) {
            value = parseFloat(value.replace(",", ".")); // Converte para número decimal
            value = `R$ ${value.toFixed(2).replace(".", ",")}`; // Formata como moeda
        }
        setValue("valorReais", value, { shouldValidate: true });
    };

    const submitForm = (data) => {
        const payload = {
            percentual: data.percentual ? parseFloat(data.percentual.replace("%", "")) / 100 : null,
            valorReais: data.valorReais
                ? parseFloat(data.valorReais.replace("R$", "").replace(",", "."))
                : null,
            pixHabilitado: data.pixHabilitado,
            percentualRotativo: data.percentualRotativo,
        };
        onSubmit(payload);
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <Row style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                <Col md={3}>
                    <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                            Reajuste em porcentagem (%)
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="(%)"
                            {...register("percentual")}
                            value={percentual}
                            onChange={(e) => handlePercentualChange(e, "percentual")}
                            disabled={!!valorReais}
                        />
                        {errors.percentual && (
                            <span style={{ color: "red", fontSize: '11px' }}>{errors.percentual.message}</span>
                        )}
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                            Reajuste em Reais (R$)
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Novo limite"
                            {...register("valorReais")}
                            value={valorReais}
                            onChange={handleValorReaisChange}
                            disabled={!!percentual}
                        />
                        {errors.valorReais && (
                            <span style={{ color: "red", fontSize: '11px' }}>{errors.valorReais.message}</span>
                        )}
                    </FormGroup>
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                            Percentual Rotativo
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="(%)"
                            {...register("percentualRotativo")}
                            value={percentualRotativo}
                            onChange={(e) => handlePercentualChange(e, "percentualRotativo")}
                        />
                        {errors.percentualRotativo && (
                            <span style={{ color: "red", fontSize: '11px' }}>{errors.percentualRotativo.message}</span>
                        )}
                    </FormGroup>
                </Col>

                <Col md={2}>
                    <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>Pix Habilitado</ControlLabel>
                        <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Controller
                                name="pixHabilitado"
                                control={control}
                                render={({ field }) => (
                                    <input
                                        type="checkbox"
                                        checked={field.value}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </div>
                    </FormGroup>
                </Col>

                <Col md={1} style={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        style={{
                            backgroundColor: "#008000",
                            color: "#FFF",
                            fontSize: 14,
                            padding: "6px 24px",
                            marginTop: '1rem'
                        }}
                        bsStyle="success"
                        simple
                        type="submit"
                        bsSize="sm"
                        disabled={selectedCompanies.length === 0} // Button is disabled if no companies are selected
                    >
                        <i className="fa fa-edit" aria-hidden="true" /> Alterar
                    </Button>
                </Col>
            </Row>
        </form>
    );
};

export default CorrecaoForm;
