import React from 'react';
import { Content, Text } from './Styles';
import Spinner from 'react-spinner-material';

export default function Loading({ Height, Description = 'Carregando...' }) {
  return (
    <Content Height={Height}>
      <Spinner
        size={120}
        spinnercolor={'#333'}
        spinnerwidth={2}
        visible={true}
      />
      <Text>{Description}</Text>
    </Content>
  );
}
