import React, { useState, useEffect } from 'react';
import MyAPI from "../../../../connections/Api.jsx";
import capitalize from "capitalize-pt-br";
import { DateTime } from "luxon";
import { Modal } from "react-bootstrap";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableSortLabel, Checkbox, TextField, Snackbar } from '@material-ui/core';
import CustomButton from '../Button/index.jsx';
import { Alert } from '@material-ui/lab';

export default function GuiasByEmployee(props) {
    const [showSnackbar, setShowSnackBar] = useState({ show: false, severity: '', message: '' });
    const [guias, setGuias] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('credenciado');
    const [selected, setSelected] = useState([]);
    const [filters, setFilters] = useState({
        credenciado: '',
        empresa: '',
        funcionario: '',
        situacao: '',
        confirmacao: '',
        canal: '',
        valor: '',
        parcela: '',
    });

    useEffect(() => {
        fetchGuias();
    }, []);

    async function fetchGuias() {
        const id = props.id;
        console.log(props.id);
        const response = await MyAPI.getGuiasByEmployee(id);

        console.log(response);
        if (response.data.length > 0) {
            setGuias(response.data);
        }
    }

    function convertDate(input) {
        if (!input) return null;

        let date;

        if (typeof input === "string") {
            // Tenta interpretar o formato específico "dd/MM/yyyy HH:mm:ss"
            date = DateTime.fromFormat(input, "dd/MM/yyyy HH:mm:ss", { zone: "utc" });
        } else if (typeof input === "number" || input instanceof Date) {
            date = DateTime.fromJSDate(new Date(input));
        } else if (DateTime.isDateTime(input)) {
            date = input;
        } else {
            return null;
        }

        return date.isValid ? date.toFormat("dd/MM/yyyy HH:mm:ss") : null;
    }

    function formatDate(date) {
        return convertDate(date);
    }

    function adicionarTaxa(valor, canal) {
        if (canal === "Alimentação" || canal === "Combustível") {
            return (Number(valor * 0.05) + Number(valor))
                .toFixed(2)
                .replace(".", ",");
        }
        return valor.toFixed(2).replace(".", ",");
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackBar({ show: false, severity: '', message: '' });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (orderBy === 'confirmacao') {
            // Converte as datas para objetos DateTime antes de comparar
            const dateA = convertDate(a[orderBy]);
            const dateB = convertDate(b[orderBy]);
            if (!dateA || !dateB) return 0; // Se alguma data for inválida, não ordena
            if (dateB < dateA) return -1;
            if (dateB > dateA) return 1;
            return 0;
        } else {
            if (b[orderBy] < a[orderBy]) return -1;
            if (b[orderBy] > a[orderBy]) return 1;
            return 0;
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleFilterChange = (column, value) => {
        setFilters({ ...filters, [column]: value });
    };

    const filterRows = (rows) => {
        return rows.filter((row) => {
            return Object.keys(filters).every((key) => {
                if (!filters[key]) return true; // Se o filtro estiver vazio, não aplica
                return String(row[key]).toLowerCase().includes(filters[key].toLowerCase());
            });
        });
    };

    const handleAdiantarParcelas = async () => {
        try {
            // Verifica se há selecionados
            if (selected.length === 0) {
                alert('Nenhuma guia selecionada!');
                return;
            }
            console.log(selected)

            // Envia os IDs para a API
            const response = await MyAPI.paidInAdvance(selected);

            console.log(response)
            // Lógica após sucesso (ajuste conforme sua API)
            if (response.data.success) {
                console.log("entrou aqui")
                setShowSnackBar({ show: true, severity: 'success', message: response.data.message })
                fetchGuias(); // Recarrega os dados se necessário
                setSelected([])
            }
        } catch (error) {
            setShowSnackBar({ show: true, severity: 'error', message: `Erro ao adiantar parcelas: ${error}` })
        }
    };

    const rows = guias.map((prop, key) => ({
        id: prop._id,
        credenciado: prop.credenciado_id ? prop.credenciado_id.nome_fantasia?.toUpperCase() : "* REMOVIDO *",
        empresa: prop.empresa_responsavel_id ? prop.empresa_responsavel_id.nome_fantasia?.toUpperCase() : "* REMOVIDO *",
        funcionario: prop.usuario_id ? capitalize(prop.usuario_id.nome)?.toUpperCase() : "* REMOVIDO *",
        situacao: prop.situacao?.toUpperCase(),
        confirmacao: prop.horario_confirmacao, // Mantém o valor original (ISO string ou outro formato)
        canal: prop.canal,
        valor: prop.valor ? `R$ ${prop.canal === "Alimentação" || prop.canal === "Combustível" ? adicionarTaxa(prop.taxa_funcionario ? (Number(prop.taxa_funcionario) * prop.valor).toFixed(2) : (prop.valor / prop.parcelas).toFixed(2), prop.canal) : prop.taxa_funcionario ? (Number(prop.taxa_funcionario) * prop.valor).toFixed(2).replace(".", ",") : (prop.valor / prop.parcelas).toFixed(2).replace(".", ",")}` : `R$ 0,00`,
        parcela: `${prop.parcela_atual}/${prop.parcelas}`,
    }));

    const filteredRows = filterRows(rows); // Aplica os filtros
    const sortedRows = stableSort(filteredRows, getComparator(order, orderBy)); // Aplica a ordenação

    return (
        <Modal
            {...props}
            bsSize='large'
            style={{ margin: 'auto' }} // Remove o overflowY do modal principal
        >
            <Modal.Header closeButton onHide={props.closeModal}>
                <Modal.Title>Guias</Modal.Title>
                <CustomButton
                    onClick={handleAdiantarParcelas}
                    disabled={selected.length === 0}
                    bsStyle="success"
                    content="Adiantar Parcelas"
                    style={{ position: 'absolute', top: '10px', right: '50px' }}
                />
            </Modal.Header>

            {/* Aplica o scroll no corpo do modal */}
            <Modal.Body style={{
                maxHeight: `calc(100vh - 140px)`, // Altura máxima baseada na tela (ajuste o valor conforme necessário)
                overflowY: 'auto', // Scroll vertical dentro do Modal.Body
                padding: 0 // Opcional: remove padding interno se a tabela for muito larga
            }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < filteredRows.length}
                                        checked={filteredRows.length > 0 && selected.length === filteredRows.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'credenciado'}
                                        direction={orderBy === 'credenciado' ? order : 'asc'}
                                        onClick={() => handleRequestSort('credenciado')}
                                    >
                                        Credenciado
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Credenciado"
                                        value={filters.credenciado}
                                        onChange={(e) => handleFilterChange('credenciado', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'empresa'}
                                        direction={orderBy === 'empresa' ? order : 'asc'}
                                        onClick={() => handleRequestSort('empresa')}
                                    >
                                        Empresa
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Empresa"
                                        value={filters.empresa}
                                        onChange={(e) => handleFilterChange('empresa', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'funcionario'}
                                        direction={orderBy === 'funcionario' ? order : 'asc'}
                                        onClick={() => handleRequestSort('funcionario')}
                                    >
                                        Funcionário
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Funcionário"
                                        value={filters.funcionario}
                                        onChange={(e) => handleFilterChange('funcionario', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'situacao'}
                                        direction={orderBy === 'situacao' ? order : 'asc'}
                                        onClick={() => handleRequestSort('situacao')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Situação"
                                        value={filters.situacao}
                                        onChange={(e) => handleFilterChange('situacao', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'confirmacao'}
                                        direction={orderBy === 'confirmacao' ? order : 'asc'}
                                        onClick={() => handleRequestSort('confirmacao')}
                                    >
                                        Confirmação
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Confirmação"
                                        value={filters.confirmacao}
                                        onChange={(e) => handleFilterChange('confirmacao', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'canal'}
                                        direction={orderBy === 'canal' ? order : 'asc'}
                                        onClick={() => handleRequestSort('canal')}
                                    >
                                        Canal
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Canal"
                                        value={filters.canal}
                                        onChange={(e) => handleFilterChange('canal', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'valor'}
                                        direction={orderBy === 'valor' ? order : 'asc'}
                                        onClick={() => handleRequestSort('valor')}
                                    >
                                        Valor
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Valor"
                                        value={filters.valor}
                                        onChange={(e) => handleFilterChange('valor', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'parcela'}
                                        direction={orderBy === 'parcela' ? order : 'asc'}
                                        onClick={() => handleRequestSort('parcela')}
                                    >
                                        Parcela
                                    </TableSortLabel>
                                    <TextField
                                        placeholder="Parcela"
                                        value={filters.parcela}
                                        onChange={(e) => handleFilterChange('parcela', e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={isItemSelected} />
                                            </TableCell>
                                            <TableCell>{row.credenciado}</TableCell>
                                            <TableCell>{row.empresa}</TableCell>
                                            <TableCell>{row.funcionario}</TableCell>
                                            <TableCell>{row.situacao}</TableCell>
                                            <TableCell>{formatDate(row.confirmacao)}</TableCell>
                                            <TableCell>{row.canal}</TableCell>
                                            <TableCell>{row.valor}</TableCell>
                                            <TableCell>{row.parcela}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        // rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={sortedRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

                <Snackbar open={showSnackbar.show} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={showSnackbar.severity}>
                        <span style={{ fontSize: '1.5rem' }}>{showSnackbar.message}</span>
                    </Alert>
                </Snackbar>
            </Modal.Body>
        </Modal >
    );
}