import React from "react";
import ReactExport from "react-data-export";
import Button from "../components/CustomButton/CustomButton.jsx";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Download({ files, fileName, name }) {
  return (
    <ExcelFile
      filename={fileName || "Guias_Emitidas"}
      element={
        <Button
          style={{
            backgroundColor: "#008000",
            color: "#FFF",
            fontSize: 16,
            width: 116,
          }}
          bsStyle="success"
          simple
          type="button"
          bsSize="sm"
        >
          <i className="fa fa-file-excel-o" aria-hidden="true" />{name || " Baixar xlsx"}
        </Button>
      }
    >
      <ExcelSheet data={files} name="Guias Gerais">
        <ExcelColumn
          label="Id"
          value={(col) => col._id}
        />
        <ExcelColumn
          label="Credenciado"
          value={(col) => col.credenciado_id.razao_social}
        />
        <ExcelColumn
          label="Empresas"
          value={(col) => col.empresa_responsavel_id.razao_social}
        />
        <ExcelColumn
          label="Funcionários"
          value={(col) => col.usuario_id.nome}
        />
        <ExcelColumn label="Situação" value={(col) => "-"} />
        <ExcelColumn
          label="Confirmação"
          value={(col) => col.horario_confirmacao}
        />
        <ExcelColumn label=" " value={"-"} />
        <ExcelColumn label="P" value={(col) => col.parcela_atual} />
        <ExcelColumn label="T" value={(col) => col.parcelas} />
        <ExcelColumn label="Canal" value={(col) => col.canal} />
        <ExcelColumn
          label="Valor"
          value={(col) =>
            col.valor
              ? col.taxa_funcionario
                ? Number((Number(col.taxa_funcionario) * col.valor).toFixed(2))
                : Number((col.valor / col.parcelas).toFixed(2))
              : 0
          }
        />
      </ExcelSheet>
    </ExcelFile>
  );
}
