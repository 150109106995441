import React from 'react';
import ReactDOM from 'react-dom';
import { isAuthenticated } from './services/autenticacoes.jsx';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './assets/css/demo.css';
import 'primeicons/primeicons.css';
import './assets/css/styleApp.css';
import './assets/css/animate.min.css';
import './assets/css/pe-icon-7-stroke.css';
// eslint-disable-next-line react-hooks/exhaustive-deps
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import './assets/css/light-bootstrap-dashboard-react.css';

import Companie from './views/Companie/Auth/Index.jsx';
import Credenciado from './views/Credenciados/Login.jsx';
import Administrativo from './views/Administrativos/Login.jsx';

import AdminLayout from '../src/Themes/Admin/Index.jsx';
import CredenciadoLayout from '../src/Themes/Busines/Index.jsx';
import CompaniesLayout from '../src/Themes/Companies/Index.jsx';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/login/empresa' exact component={Companie} />
      <Route path='/login/credenciado' exact component={Credenciado} />
      <Route path='/login/administrativo' exact component={Administrativo} />

      <PrivateRoute
        path='/empresa'
        component={(props) => <CompaniesLayout {...props} />}
      />
      <PrivateRoute
        path='/credenciado'
        component={(props) => <CredenciadoLayout {...props} />}
      />
      <PrivateRoute
        path='/administrativo'
        component={(props) => <AdminLayout {...props} />}
      />

      <Redirect path='*' to='/login/credenciado' />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
