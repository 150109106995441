import jsPDF from "jspdf";
import moment from "moment";
import { Formatacao } from "../../../utils/Functions/Formatacoes.jsx";
import Capitalize from "capitalize-pt-br";
import { relatorioGeral, relatorio } from "./ModeloBase.jsx";

const report = (
  Codigo,
  Canal,
  Data,
  Venda,
  local,
  metodo,
  empresa,
  cliente,
  Parcelas,
  observacao,
  parcela_atual,
  segunda_via,
  taxa_ramicard
) => {
  let doc;

  if (segunda_via) {
    doc = new jsPDF("r", "mm", [315, 600]);
  } else {
    doc = new jsPDF("l", "mm", [841.79, 595.28]);
  }

  const date = new Date();

  const totalBruto =
    Venda > 0
      ? taxa_ramicard === null
        ? Venda / Parcelas
        : Venda * taxa_ramicard
      : Venda;

  // - 1º Via do Recibo Ramicard

  doc.setFont("Courier");
  doc.setFontType("normal");

  doc.addImage(String(relatorio), "PNG", 1, 1, 297, 210);

  doc.setFontSize(12);
  doc.text(33, 21.5, Codigo);

  doc.setFontSize(8);
  doc.text(
    41.7,
    15,
    `${String(date.getDate()).length === 1
      ? `0${date.getDate()}`
      : `${date.getDate()}`
    }/${String(date.getMonth() + 1).length === 1
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`
    }/${date.getFullYear()} ${date.toTimeString().split(" ")[0]}`
  );

  doc.setFontSize(12);
  doc.text(11.5, 38, Capitalize(String(local).slice(0, 35)));

  doc.setFontSize(12);
  doc.text(12, 62.4, Capitalize(Canal));

  doc.setFontSize(8);
  doc.text(41.7, 72, Data);

  doc.setFontSize(10);
  doc.text(
    65.3,
    62.5,
    `${!parcela_atual ? "1" : parcela_atual}/${Parcelas === undefined ? "1" : Parcelas}`
  );
  doc.text(84.4, 62.4, `${String(totalBruto.toFixed(2).replace(".", ","))}`);

  doc.text(78, 92.3, `R$ ${String(Venda.toFixed(2).replace(".", ","))}`);

  doc.text(50, 81.5, `${metodo}`);

  doc.text(28, 113.6, `${String(empresa).slice(0, 35)}`);
  doc.text(27, 124.8, `${String(cliente).slice(0, 35)}`);

  doc.setFontSize(10);
  doc.text(11, 133, `Observações:`);

  doc.setFontSize(8);
  doc.text(
    14,
    138,
    `${observacao ? String(observacao).slice(0, 34) : "Sem observações"}`
  );
  doc.text(14, 142, `${observacao ? String(observacao).slice(34) : ""}`);

  // - 2º Via do Recibo Ramicard

  doc.setFontSize(12);
  doc.text(138, 21.5, Codigo);

  doc.setFontSize(8);
  doc.text(
    145.7,
    15,
    `${String(date.getDate()).length === 1
      ? `0${date.getDate()}`
      : `${date.getDate()}`
    }/${String(date.getMonth() + 1).length === 1
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`
    }/${date.getFullYear()} ${date.toTimeString().split(" ")[0]}`
  );

  doc.setFontSize(12);
  doc.text(116, 38, Capitalize(String(local).slice(0, 35)));

  doc.setFontSize(12);
  doc.text(120, 62.4, Capitalize(Canal));

  doc.setFontSize(8);
  doc.text(145.7, 72, Data);

  doc.setFontSize(10);
  doc.text(
    171,
    62.5,
    `${!parcela_atual ? "1" : parcela_atual}/${Parcelas === undefined ? "1" : Parcelas
    }`
  );
  doc.text(190, 62.4, `${String(totalBruto.toFixed(2).replace(".", ","))}`);

  doc.text(184, 92.3, `R$ ${String(Venda.toFixed(2).replace(".", ","))}`);

  doc.text(155.3, 81.5, `${metodo}`);

  doc.text(134, 113.6, `${String(empresa).slice(0, 35)}`);
  doc.text(133, 124.8, `${String(cliente).slice(0, 35)}`);

  doc.setFontSize(10);
  doc.text(117, 133, `Observações:`);

  doc.setFontSize(8);
  doc.text(
    117,
    138,
    `${observacao ? String(observacao).slice(0, 34) : "Sem observações"}`
  );
  doc.text(117, 142, `${observacao ? String(observacao).slice(34) : ""}`);

  window.open(doc.output("bloburl"));
};

const generalReport = (data_01, vendas, accredited, dataInicial, dataFinal) => {
  const Organizador = (Item) => {
    return Item.sort((a, b) =>
      b.data_transacao.split(' ')[0].split('/').reverse().join() < a.data_transacao.split(' ')[0].split('/').reverse().join() ? 1 : a.data_transacao.split(' ')[0].split('/').reverse().join() < b.data_transacao.split(' ')[0].split('/').reverse().join() ? -1 : 0
    );
  }

  const Guias = Organizador(data_01)

  function formatarMoeda(valor) {
    valor = parseFloat(valor).toFixed(2);

    let valorFormatado = parseFloat(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    const partes = valorFormatado.split(/(\s+)/);    
    const larguraDesejada = 12; // ajuste conforme necessário
    const espacos = larguraDesejada - (valorFormatado.length);

    valorFormatado = partes[0] + partes[1].repeat(espacos) + partes[2];

    return valorFormatado;
  }

  const valorTotalData = (data, model) => {
    if (model === "brut") {
      return formatarMoeda(
        vendas
          .filter((e) => e.data_transacao.split(' ')[0] === data)
          .filter((e) => e.situacao !== "Cancelado")
          .map((e) => e.valor)
          .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
      );

    } else if (model === "desc") {
      return formatarMoeda(
        vendas
          .filter((e) => e.data_transacao.split(' ')[0] === data)
          .filter((e) => e.situacao !== "Cancelado")
          .map((e) => e.valor * (e.taxa_ramicard / 100))
          .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
      );
    } else {
      return formatarMoeda(
        vendas
          .filter((e) => e.data_transacao.split(' ')[0] === data)
          .filter((e) => e.situacao !== "Cancelado")
          .map((e) => e.valor - e.valor * (e.taxa_ramicard / 100))
          .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
      );
    }
  };

  const buscarTaxa = (canal) =>
    accredited.canais_ativos
      .map((e) => (e.canal === canal ? e.taxa : null))
      .filter((e) => e !== null)[0] / 100;  

  const valorTotal = (model) => {
    if (model === "brut") {
      return formatarMoeda(vendas
        .filter((item) => item.situacao !== "Cancelado")
        .reduce((somador, atual) => somador + atual.valor, 0)
      );
    } else if (model === "desc") {
      return formatarMoeda(vendas
        .filter((item) => item.situacao !== "Cancelado")
        .reduce(
          (somador, atual) => somador + atual.valor * buscarTaxa(atual.canal),
          0
        ));
    } else {
      return formatarMoeda( vendas
        .filter((item) => item.situacao !== "Cancelado")
        .reduce(
          (somador, atual) =>
            somador + (atual.valor - atual.valor * buscarTaxa(atual.canal)),
          0
        ));
    }
  };

  const trasacoesTotais = (data) => {
    return `${
      vendas
        .filter((e) => e.data_transacao.split(' ')[0] === data)
        .filter((e) => e.situacao !== "Cancelado").length
    }`;
  };

  

  let report = new jsPDF();

  let pag = 1;
  let distance = 60;

  const header = () => {
    report.addImage(String(relatorioGeral), "PNG", 0, 0, 210, 297);

    report.setFont("Courier");

    report.setFontSize(10);
    report.setFontType("normal");
    report.text(83, 6, moment().format("DD/MM/YYYY HH:mm:ss"));

    report.setFontSize(18);
    report.text(10, 16, accredited.nome_fantasia.toUpperCase());

    report.setFontSize(10);
    report.text(
      10,
      20.5,
      `${String(accredited.cnpj_cpf).length <= 11
        ? Formatacao("CPF", accredited.cnpj_cpf)
        : Formatacao("CNPJ", accredited.cnpj_cpf)
      }`
    );

    report.setFontSize(10);
    report.text(
      44.5,
      47,
      `Relatório do somatório dos valores agrupados por data.`
    );

    report.setFontSize(10);
    report.text(10, 58, "DATA CONSUMO");
    report.text(40, 58, "NUMERO DE VENDAS");
    report.text(103, 58, "VALOR BRUTO");
    report.text(133, 58, "TAXA RAMICARD");
    report.text(167, 58, "VALOR LÍQUIDO");

    report.setFontSize(10);
    report.setFontType("bold");
    report.text(195, 289.1, `${Math.ceil(pag)}`);
  };

  let index = 1;

  while (index <= Math.ceil(Guias.length / 42)) {
    header();

    for (let count = 0; count < 42; count++) {
      distance += 5;

      if (Guias[index * 42 - 42 + count] !== undefined) {
        report.setFontType("normal");
        report.text(
          10,
          distance,
          `${Guias[
            index * 42 - 42 + count
          ].data_transacao.split(' ')[0]}`
        );
        report.text(
          40,
          distance,
          `${trasacoesTotais(Guias[index * 42 - 42 + count].data_transacao.split(' ')[0])} venda(s) finalizada`
        );
        report.text(
          104,
          distance,          
          valorTotalData(
            Guias[index * 42 - 42 + count].data_transacao.split(' ')[0],
            "brut"
          )
        );
        report.text(
          137,
          distance,
          valorTotalData(
            Guias[index * 42 - 42 + count].data_transacao.split(' ')[0],
            "desc"
          )
        );
        report.text(
          172,
          distance,
          valorTotalData(
            Guias[index * 42 - 42 + count].data_transacao.split(' ')[0]
          )
        );
      }
    }

    distance = 60;
    pag++;

    if (index !== Math.ceil(Guias.length / 42)) report.addPage();

    index++;
  }

  report.text(80, 270, "Totais:");
  report.text(103, 270, "Bruto");
  report.text(104, 275, valorTotal("brut"));
  report.text(133, 270, "Taxa");
  report.text(137, 275, valorTotal("desc"));
  report.text(167, 270, "Líquido");
  report.text(172, 275, valorTotal());

  window.open(report.output("bloburl"));
};

const detailedReport = (data_01, vendas, accredited, dataInicial, dataFinal) => {
  const Organizador = (Item) => {
    return Item.sort((a, b) =>
      b.horario_confirmacao < a.horario_confirmacao ? 1 : a.horario_confirmacao < b.horario_confirmacao ? -1 : 0
    );
  }

  const Guias = Organizador(data_01)

  const buscarTaxa = (canal) =>
    accredited.canais_ativos
      .map((e) => (e.canal === canal ? e.taxa : null))
      .filter((e) => e !== null)[0] / 100;

  const valorTotal = (model) => {
    if (model === "brut") {
      return vendas
        .filter((item) => item.situacao !== "Cancelado")
        .reduce((somador, atual) => somador + atual.valor, 0)
        .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else if (model === "desc") {
      return vendas
        .filter((item) => item.situacao !== "Cancelado")
        .reduce(
          (somador, atual) => somador + atual.valor * buscarTaxa(atual.canal),
          0
        )
        .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else {
      return vendas
        .filter((item) => item.situacao !== "Cancelado")
        .reduce(
          (somador, atual) =>
            somador + (atual.valor - atual.valor * buscarTaxa(atual.canal)),
          0
        )
        .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    }
  };

  let report = new jsPDF();

  let pag = 1;
  let distance = 60;

  const header = () => {
    report.addImage(String(relatorioGeral), "PNG", 0, 0, 210, 297);

    report.setFont("Courier");

    report.setFontSize(10);
    report.setFontType("normal");
    report.text(83, 6, moment().format("DD/MM/YYYY HH:mm:ss"));

    report.setFontSize(18);
    report.text(10, 16, accredited.nome_fantasia.toUpperCase());

    report.setFontSize(10);
    report.text(
      10,
      20.5,
      `${String(accredited.cnpj_cpf).length <= 11
        ? Formatacao("CPF", accredited.cnpj_cpf)
        : Formatacao("CNPJ", accredited.cnpj_cpf)
      }`
    );

    report.setFontSize(12);
    report.text(
      77,
      47,
      `${dataInicial === null ? "" : dataInicial} ${dataInicial !== null && dataFinal !== null ? "à" : ""
      } ${dataFinal === null ? "" : dataFinal}`
    );

    report.setFontSize(10);
    report.text(15, 58, "DATA DA VENDA");
    report.text(88, 58, "VALOR BRUTO");
    report.text(118, 58, "TAXA RAMICARD");
    report.text(155, 58, "VALOR LÍQUIDO");

    report.setFontSize(10);
    report.setFontType("bold");
    report.text(195, 289.1, `${Math.ceil(pag)}`);
  };

  let index = 1;

  while (index <= Math.ceil(Guias.length / 42)) {
    header();

    for (let count = 0; count < 42; count++) {
      distance += 5;

      if (Guias[index * 42 - 42 + count] !== undefined) {
        report.setFontType("normal");
        report.text(
          16,
          distance,
          `${Guias[index * 42 - 42 + count].horario_confirmacao}`
        );
        report.text(
          89,
          distance,
          Number(Guias[index * 42 - 42 + count].valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        );
        report.text(
          119,
          distance,
          Number(
            Guias[index * 42 - 42 + count].valor *
            (Guias[index * 42 - 42 + count].taxa_ramicard / 100)
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        );
        report.text(
          156,
          distance,
          Number(
            Guias[index * 42 - 42 + count].valor -
            Guias[index * 42 - 42 + count].valor *
            (Guias[index * 42 - 42 + count].taxa_ramicard / 100)
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        );
      }
    }

    distance = 60;
    pag++;

    if (index !== Math.ceil(Guias.length / 42)) report.addPage();

    index++;
  }

  report.text(68, 270, "Totais:");
  report.text(88, 270, "Bruto");
  report.text(89, 275, valorTotal("brut"));
  report.text(118, 270, "Taxa");
  report.text(119, 275, valorTotal("desc"));
  report.text(155, 270, "Líquido");
  report.text(156, 275, valorTotal());

  window.open(report.output("bloburl"));
};

export { report, generalReport, detailedReport };
