import styled from 'styled-components';
import React, { useState } from 'react';
import Loading from '../../Components/Loading';
import { Grid, Row, Col } from 'react-bootstrap';
import MyAPI from '../../../../connections/Api.jsx';
import { StatsCard } from '../../../../components/StatsCard/StatsCard.jsx';

import Ramicash from './Ramicash.jsx';
import Notifications from './Notifications.jsx';

export const Content = styled.div`
  cursor: pointer;
`;

export default function Dashboard() {
  const [data, setData] = useState(null);
  const [ramicashData, setRamicashData] = useState(null);

  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = React.useState('Notifications');

  React.useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await MyAPI.getInformations();

        setData(data);

        const ramicash = await MyAPI.getRamicashList();
        
        setRamicashData(ramicash.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [refresh]);

  if (data === null) return <Loading Height='950px' />;

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col lg={4}>
            <Content onClick={() => setPage('Notifications')}>
              <StatsCard
                isSelected={page === 'Notifications'}
                bigIcon={
                  <i
                    className='pe-7s-paper-plane'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Notificações Enviadas'
                statsValue={data?.notifications || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText='Total de mensagens enviadas'
              />
            </Content>
          </Col>
          <Col lg={4}>
            <Content onClick={() => setPage('Ramicash')}>
              <StatsCard
                isSelected={page === 'Ramicash'}
                bigIcon={
                  <i
                    className='pe-7s-cash'
                    style={{ color: 'rgba(0,0,0,.7)' }}
                  />
                }
                statsText='Novas Solicitações Ramicash'
                statsValue={ramicashData?.lastRequests.length || 0}
                statsIcon={<i className='fa fa-info-circle' />}
                statsIconText={`Total de pedidos ${ramicashData?.ramicashCount || 0}`}
              />
            </Content>
          </Col>
          <Col lg={4}>
            <StatsCard
              bigIcon={
                <i
                  className='pe-7s-users'
                  style={{ color: 'rgba(0,0,0,.7)' }}
                />
              }
              statsText='Usuários Online'
              statsValue={data?.allUsers || 0}
              statsIcon={<i className='fa fa-info-circle' />}
              statsIconText='Total de usuários ativos'
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {
              {
                Notifications: (
                  <Notifications
                    refresh={() => setRefresh(!refresh)}
                    data={data}
                  />
                ),
                Ramicash: (
                  <Ramicash refresh={() => setRefresh(!refresh)} data={ramicashData} />
                ),
              }[page]
            }
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
