import { DateTime } from "luxon";
import { TimeZone } from "./Variables";

const logoutSystem = () => localStorage.clear();

const getToken = () => localStorage.getItem('Token');

const justNumbers = (value) => value.replace(/\D/g, "");

const getStatusCode = (e) => e.message.split("code ")[1];

const setItens = (label, value) => localStorage.setItem(label, value);

const saveItens = (item) => item.forEach((e) => setItens(e.name, e.value));

const isoShortDate = (value, type) => DateTime.fromISO(value).toFormat(type);

const anyIso = (value = null, type) => DateTime.fromFormat(value, type).toISO();

const grandSum = (values) => values.reduce((all, current) => all + current, 0)

const inReal = (value) => value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });

const forCNPJ = (cnpj) => `${cnpj}`.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

const authorizationDate = (item) => DateTime.fromFormat(item.horario_confirmacao, TimeZone).plus({ months: -(item.parcela_atual - 1) }).toFormat(TimeZone);

export {
  inReal,
  anyIso,
  forCNPJ,
  getToken,
  grandSum,
  saveItens,
  justNumbers,
  logoutSystem,
  isoShortDate,
  getStatusCode,
  authorizationDate,
};
