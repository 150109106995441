import React from 'react';
import { Content, Text, Image } from './Styles/styles'

export default function NotFound({ Message, ...props }) {
    return (
        <Content {...props}>
            <Image />
            <Text >{ Message || 'Sem resultados!' }</Text>
        </Content>
    )
}