import React, { useState } from "react";
import Loading from "../../../Components/Loading";
import MyAPI from "../../../../../connections/Api.jsx";
import DatePicker from "react-16-bootstrap-date-picker";
import Notifications from "../../../Components/Toast.jsx";
import Card from "../../../../../components/Card/Card.jsx";
import Button from "../../../../../components/CustomButton/CustomButton.jsx";

import { MultiSelect } from "primereact/multiselect";
import { ToastProvider } from "react-toast-notifications";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Table,
} from "react-bootstrap";
import { DatePikerConfig } from "../../../../../utils/Abstractions/Variables.jsx";
import {
  anyIso,
  isoShortDate,
} from "../../../../../utils/Abstractions/Functions.jsx";
import {
  ReceberEmpresasXlsx
} from "../../../../../services/Excel/Exportacoes-xlsx.jsx";

import * as S from "./Styles";

export default function Empresas({ goTo }) {
  const [data, setData] = useState([]);
  const [selected, setSelect] = useState([]);
  const [error, setError] = React.useState([]);
  const [finalDate, setFinalDate] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [initialDate, setInitialDate] = React.useState("");
  const [onSelectedList, setOnSelectedList] = useState(false);
  const [selectedFechamento, setSelectFechamento] = useState([]);
  const [onSelectedFechamento, setOnSelectedFechamento] = useState(false);
  const [selectedSituacoes, setSelectSituacoes] = React.useState([]);
  const [onSelectedSituacoes, setOnSelectedSituacoes] = React.useState(false);

  const situacoes = [
    { name: "Aberta", code: "Aberta" },
    { name: "Adiantada", code: "Adiantada" },
    { name: "Cancelado", code: "Cancelado" },
    { name: "Fechado", code: "Fechado" },
    { name: "Pendente", code: "Pendente" },
    { name: "Rescisao", code: "Rescisao" }
  ];

  const fetchData = async () => {
    let errors = 0;

    if (selected.length === 0) {
      errors++;
      setError([
        {
          message: "Selecione pelo menos um canal!",
          appearance: "warning",
          auto_dismiss: true,
        },
        ...error,
      ]);
    }

    if (finalDate === "") {
      errors++;
      setError([
        {
          message: "Insira o período final!",
          appearance: "warning",
          auto_dismiss: true,
        },
        ...error,
      ]);
    }

    if (initialDate === "") {
      errors++;
      setError([
        {
          message: "Insira o período inicial!",
          appearance: "warning",
          auto_dismiss: true,
        },
        ...error,
      ]);
    }

    try {
      if (errors === 0) {
        setLoading(true);
        const response = await MyAPI.getVoucherList({
          initialDate,
          finalDate,
          channels: selected.map((e) => e.name),
          situacoes: selectedSituacoes.map(e => e.name)
        });

        const closedDays = selectedFechamento.map((e) => Number(e.dia));

        if (closedDays.length > 0) {
          setData(
            response.data.filter((e) =>
              closedDays.includes(e.empresa_responsavel_id.dia_fechamento)
            )
          );
        } else {
          setData(response.data);
        }

        setLoading(false);
      }
    } catch (e) {
      setError([
        {
          message: "Não foi possível buscar os dados de sua empresa!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }
  };

  const downloadReportByCompany = async (empresa_responsavel_id, fileName) => {
    try {
      const response = await MyAPI.getVoucherReport({
        initialDate,
        finalDate,
        channels: selected.map((e) => e.name),
        empresa_responsavel_id,
        fileName,
        situacoes: selectedSituacoes.map(e => e.name)
      });
      
      if (response.error) {
        alert(response.message);
      } else {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = `${fileName}.csv`;
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (e) {
      setError([
        {
          message: "Erro ao baixar o arquivo da empresa!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }
  }

  const downloadResumeReport = async (fileName) => {
    try {
      const response = await MyAPI.getVoucherToReceive({
        initialDate,
        finalDate,
        channels: selected.map((e) => e.name),
        situacoes: selectedSituacoes.map(e => e.name),
        fileName
      });
      
      if (response.error) {
        alert(response.message);
      } else {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = `${fileName}.csv`;
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (e) {
      setError([
        {
          message: "Erro ao baixar o arquivo da empresa!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }
  }

  const cabecalhos = [
    "Código",
    "Empresa",
    "Valor no Período",
    "Exportar planinha",
  ];

  // * Altera este trecho de modo que busque no servidor estes mesmos dados.
  const cartoes = [
    { name: "Alimentação", code: "AL" },
    { name: "Produtos", code: "PR" },
    { name: "Serviços", code: "SE" },
    { name: "Combustível", code: "CO" },
    { name: "Ramicash", code: "RC" }
  ];

  const fechamentos = [
    { fechamento: "Dia 25", dia: "25" },
    { fechamento: "Dia 24", dia: "24" },
  ];

  const TaxaAlimentacao = 0.05;

  const valorTotalEmpresaPeriodo = (cnpj_cpf) => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };

    const ValorFinal = data
      .filter((e) => e.empresa_responsavel_id.cnpj === cnpj_cpf)
      .map((e) =>
        e.canal === "Alimentação" || e.canal === "Combustível"
          ? CartaoAlimentacao(
              e.taxa_funcionario
                ? e.valor * Number(e.taxa_funcionario)
                : e.valor / e.parcelas
            )
          : e.taxa_funcionario
          ? e.valor * Number(e.taxa_funcionario)
          : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const aplicarFormatacao = (valorString) => {
    let valorFloat;
    const tamanhoDoValor = valorString.length;

    if (1 === tamanhoDoValor) {
      valorFloat = parseFloat("0.0" + valorString);
    } else if (2 === tamanhoDoValor) {
      valorFloat = parseFloat("0." + valorString);
    } else if (tamanhoDoValor > 2) {
      const centavos = valorString.slice(-2);
      const parteInteira = valorString.slice(0, tamanhoDoValor - 2);

      valorFloat = parseFloat(parteInteira.concat(centavos));
    }

    return isNaN(valorFloat)
      ? valorString
      : valorFloat.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
        });
  };

  const formatarMoeda = (valor) => {
    switch (typeof valor) {
      case "string":
        const valorSemEspacos = valor.trim();
        return isNaN(parseFloat(valorSemEspacos))
          ? valor
          : aplicarFormatacao(valorSemEspacos);

      case "number":
        return aplicarFormatacao(String(valor));
      default:
        return valor;
    }
  };

  const valorTotalEmpresas = () => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };
    const ValorFinal = data
      .map((e) =>
        e.canal === "Alimentação" || e.canal === "Combustível"
          ? CartaoAlimentacao(
              e.taxa_funcionario
                ? e.valor * e.taxa_funcionario
                : e.valor / e.parcelas
            )
          : e.taxa_funcionario
          ? e.valor * e.taxa_funcionario
          : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const GerarArquivo = () => {
    let dataSet = [
      {
        columns: [
          {
            title: "CÓDIGO",
            width: { wpx: 90 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "EMPRESA",
            width: { wch: 60 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "VALOR NO PERÍODO",
            width: { wpx: 140 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "VALOR FUTURO",
            width: { wpx: 125 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
          {
            title: "VALOR TOTAL",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          },
        ],
        data: [],
      },
    ];

    data
      .filter(function (a) {
        return (
          !this[JSON.stringify(a.empresa_responsavel_id.razao_social)] &&
          (this[JSON.stringify(a.empresa_responsavel_id.razao_social)] = true)
        );
      }, Object.create(null))
      .map((item) =>
        item
          ? dataSet[0].data.push([
              {
                value: `${
                  item.empresa_responsavel_id.registro
                    ? item.empresa_responsavel_id.registro
                    : "-"
                }`,
                style: {
                  font: { sz: "12" },
                  alignment: { horizontal: "center" },
                },
              },
              {
                value: `${item.empresa_responsavel_id.nome_fantasia}`,
                style: {
                  font: { sz: "12" },
                  alignment: { horizontal: "center" },
                },
              },
              {
                value: Number(
                  valorTotalEmpresaPeriodo(item.empresa_responsavel_id.cnpj)
                ),
                style: {
                  numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
                  font: { sz: "12" },
                  alignment: { horizontal: "center" },
                },
              },
            ])
          : false
      )
      .filter((e) => e !== false);

    dataSet[0].data.push([
      { value: " " },
      {
        value: "Total à Receber: ",
        style: {
          font: { sz: "12", bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        value: Number(valorTotalEmpresas()),
        style: {
          numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "12", bold: true },
          alignment: { horizontal: "center" },
        },
      },
    ]);

    return dataSet;
  };

  const nameFile = (name) => name.split(" ").join("_");

  const thisCompany = (document) =>
    data.filter((voucher) => voucher.empresa_responsavel_id.cnpj === document);

  return (
    <div className="content">
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12} style={{ marginTop: 25 }}>
            <Card
              title={
                <>
                  <Button
                    onClick={() => goTo("Menu")}
                    style={{ backgroundColor: "#008000", color: "#FFF" }}
                    simple={true}
                    type="button"
                    bsSize="sm"
                  >
                    {" "}
                    <i className="fa fa-bars" aria-hidden="true" /> Menu
                  </Button>
                  <br />
                  <p align="center" style={{ fontSize: 20 }}>
                    Relatório de recebimento das Empresas
                  </p>
                </>
              }
              content={
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período inicial
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(initialDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setInitialDate(isoShortDate(e, "dd/MM/yyyy"))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período Final
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(finalDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setFinalDate(isoShortDate(e, "dd/MM/yyyy"))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Cartões
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedList(false)}
                      onMouseOver={() => setOnSelectedList(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selected}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedList ? 0.3 : 1,
                        }}
                        options={cartoes}
                        placeholder={`Cartões Ramicard`}
                        onChange={(e) => setSelect(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Situação
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedSituacoes(false)}
                      onMouseOver={() => setOnSelectedSituacoes(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selectedSituacoes}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedSituacoes ? 0.3 : 1,
                        }}
                        options={situacoes}
                        placeholder={`Situações das Guias`}
                        onChange={(e) => setSelectSituacoes(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Dias de Fechamento
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedFechamento(false)}
                      onMouseOver={() => setOnSelectedFechamento(true)}
                    >
                      <MultiSelect
                        optionLabel="fechamento"
                        value={selectedFechamento}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedFechamento ? 0.3 : 1,
                        }}
                        options={fechamentos}
                        placeholder={`Fechamentos`}
                        onChange={(e) => setSelectFechamento(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <S.WarapperButtons>
                      <Button
                        onClick={() => fetchData()}
                        style={{
                          backgroundColor: "#008000",
                          color: "#FFF",
                          marginRight: 15,
                          fontSize: 16,
                          width: 116,
                        }}
                        simple
                        type="button"
                        bsSize="sm"
                      >
                        <i className="fa fa-search" aria-hidden="true" />{" "}
                        Pesquisar
                      </Button>
                      {data.length > 0 ? (
                        <Button
                          onClick={() => downloadResumeReport('recebimentos-empresa.csv')
                          }
                          style={{
                            backgroundColor: `${"#008000"}`,
                            color: "#FFF",
                            marginLeft: "10px",
                          }}
                          bsStyle="success"
                          simple={true}
                          type="button"
                        >
                          {" "}
                          <i
                            className="fa fa-file-text-o"
                            aria-hidden="true"
                          />{" "}
                          Exportar Planilha
                        </Button>
                      ) : null}
                    </S.WarapperButtons>
                  </Col>
                </Row>
              }
            />

            {!isLoading ? (
              <Card
                content={
                  <div>
                    <div align="center" style={{ margin: 20 }}>
                      <Table striped hover>
                        <thead>
                          <tr>
                            {cabecalhos.map((prop, key) => {
                              return (
                                <th
                                  key={key}
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: "#000000",
                                  }}
                                >
                                  {prop}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody align="center">
                          {selected.length > 0 &&
                          String(finalDate).length === 10 &&
                          String(initialDate).length === 10 ? (
                            data
                              .filter(function (a) {
                                return (
                                  !this[
                                    JSON.stringify(
                                      a.empresa_responsavel_id.razao_social
                                    )
                                  ] &&
                                  (this[
                                    JSON.stringify(
                                      a.empresa_responsavel_id.razao_social
                                    )
                                  ] = true)
                                );
                              }, Object.create(null))
                              .map((prop, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{`${
                                      prop.empresa_responsavel_id.registro
                                        ? prop.empresa_responsavel_id.registro
                                        : "-"
                                    }`}</td>
                                    <td>{`${prop.empresa_responsavel_id.nome_fantasia}`}</td>
                                    <td style={{ textAlign: "center" }}>
                                      R$ {formatarMoeda(
                                            String(
                                              valorTotalEmpresaPeriodo(
                                                prop.empresa_responsavel_id.cnpj
                                              )
                                            )
                                          )}
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            {prop.empresa_responsavel_id
                                              ?.nome_fantasia ||
                                              prop.empresa_responsavel_id
                                                .razao_social}
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          onClick={() => downloadReportByCompany(prop.empresa_responsavel_id._id, nameFile(
                                            prop.empresa_responsavel_id
                                              ?.nome_fantasia ||
                                              prop.empresa_responsavel_id
                                                .razao_social
                                            ))
                                          }
                                          style={{
                                            backgroundColor: `${"#008000"}`,
                                            color: "#FFF",
                                            marginLeft: "10px",
                                          }}
                                          bsStyle="success"
                                          simple={true}
                                          type="button"
                                        >
                                          {" "}
                                          <i
                                            className="fa fa-file-text-o"
                                            aria-hidden="true"
                                          />{" "}
                                          Exportar Planilha
                                        </Button>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          )}
                          {selected.length > 0 &&
                          String(finalDate).length === 10 &&
                          String(initialDate).length === 10 ? (
                            <tr>
                              <td />
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                Total Geral:
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {data
                                  ? `R$ ${formatarMoeda(
                                      String(valorTotalEmpresas())
                                    )}`
                                  : "-"}
                              </td>
                              <td />
                            </tr>
                          ) : (
                            <tr>
                              <td />
                              <td
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                Total Geral:
                              </td>
                              <td style={{ fontWeight: "bold" }}>-</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                }
              />
            ) : (
              <Loading Height="400px" />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
