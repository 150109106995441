import React from 'react';
import { Modal, Button } from "react-bootstrap";

export default function ModalConfirmacao(props) {
   return (
      <Modal {...props} centered={`${true}`} style={{ margin: 'auto' }}>
         <Modal.Header closeButton>
            <Modal.Title>
               {props.title}
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <p>{props.pergunta}</p>
         </Modal.Body>
         <Modal.Footer>
            <Button 
               onClick={() => { props.onConfirma() }}
               style={{
                  border: 0,
                  color: 'white',
                  borderRadius: 3,
                  fontSize: '14px',
                  background: 'rgba(35,142,35, .8)',
                  boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                }}
            >{props.confirma}</Button>
            <Button
               style={{
                  border: 0,
                  color: 'white',
                  borderRadius: 3,
                  fontSize: '14px',
                  background: 'rgba(255,0,0, .8)',
                  boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
               }}                
               onClick={() => { props.onCancela() }}
            >{props.cancela}</Button>
         </Modal.Footer>
      </Modal>
   );
}