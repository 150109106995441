import React, { useState, useEffect } from 'react';
import Card from '../../../../components/Card/Card.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    Table,
} from 'react-bootstrap';
import { Alert } from '@material-ui/lab';

import { fullSimulation, simulation } from '../../../../utils/Tax.js';
import MyAPI from "../../../../connections/Api.jsx";
import { Dropdown } from 'primereact/dropdown';
import { Snackbar } from '@material-ui/core';

export default function NewSimulator() {
    const [valor, setValor] = useState(0);
    const [numParcelas, setNumParcelas] = useState(0);
    const [taxaJuros, setTaxaJuros] = useState(0);

    const [totalParcelas, setTotalParcelas] = useState(0);
    const [totalJuros, setTotalJuros] = useState(0);
    const [simulacao, setSimulacao] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [showSnackbar, setShowSnackBar] = useState(false);
    const [produtoSelecionado, setProdutoSelecionado] = useState({});
    const [produtos, setProdutos] = useState([]);

    // useEffect(() => {
    //     async function refreshScreen() {
    //         setRefresh(false);
    //     }
    //     refreshScreen();
    // }, [refresh]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const products = await MyAPI.getCanais();
                setProdutos(products.data);
                console.log(products);
            } catch (error) {
                console.error("Erro ao buscar produtos:", error);
            }
        };

        fetchProducts();
    }, [])

    const gerarSimulacao = () => {
        console.log(produtoSelecionado, valor)
        if (Object.keys(produtoSelecionado).length === 0 || valor == '') {
            setShowSnackBar(true)
            return
        }
        const taxa = produtoSelecionado.taxa_parcelamento ? produtoSelecionado.taxa_parcelamento : 3.5;
        const result = fullSimulation(
            Number(valor),
            Number(taxa) / 100,
            Number(produtoSelecionado.parcelas_max)
        );

        console.log(result)

        // setTotalParcelas(
        //     result.installments.reduce((acc, val) => (acc += val.value), 0)
        // );
        // setTotalJuros(result.totalInterest);
        setSimulacao(result);

        // setRefresh(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowSnackBar(false);
      };

    return (
        <div className='content'>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title='Simulador de Parcelas Por Produto'
                            content={
                                <div>
                                    <Row>
                                        <Col sm={12} md={3} lg={2}>
                                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                                                <ControlLabel style={{ color: '#000' }}>
                                                    Produto
                                                </ControlLabel>
                                                <Dropdown
                                                    style={{ height: '3.9rem', lineHeight: '2.6rem', width: '100%' }}
                                                    optionLabel='canal'
                                                    placeholder='Selecionar...'
                                                    value={produtoSelecionado}
                                                    options={produtos}
                                                    onChange={(e) => setProdutoSelecionado(e.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={3} lg={3}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: '#000' }}>
                                                    Valor
                                                </ControlLabel>
                                                <FormControl
                                                    type='text'
                                                    autoFocus
                                                    required={true}
                                                    bsClass='form-control'
                                                    placeholder='Exemplo: 10000'
                                                    onChange={(event) => setValor(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* <Col sm={12} md={3} lg={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: '#000' }}>
                                                    Numero de Parcelas
                                                </ControlLabel>
                                                <FormControl
                                                    type='text'
                                                    required={true}
                                                    bsClass='form-control'
                                                    placeholder='Exemplo: 10'
                                                    defaultValue={0}
                                                    onChange={(event) =>
                                                        setNumParcelas(event.target.value)
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={3} lg={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: '#000' }}>
                                                    Taxa de Juros (Mês) %
                                                </ControlLabel>
                                                <FormControl
                                                    type='number'
                                                    required={true}
                                                    bsClass='form-control'
                                                    placeholder='Exemplo: 3,5'
                                                    defaultValue={0}
                                                    onChange={(event) => setTaxaJuros(event.target.value)}
                                                />
                                            </FormGroup>
                                        </Col> */}

                                        <Col sm={12} md={3} lg={2}>
                                            <FormGroup>
                                                <Button
                                                    type='submit'
                                                    variant='contained'
                                                    bsStyle='info'
                                                    style={{
                                                        marginTop: '26px',
                                                        marginLeft: '10px',
                                                        background: 'rgba(35,142,35, .8)',
                                                        border: 0,
                                                        borderRadius: 3,
                                                        boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                                        color: 'white',
                                                        fontSize: '14px',
                                                    }}
                                                    onClick={() => gerarSimulacao()}
                                                >
                                                    {' '}
                                                    Calcular
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row style={{ height: '30px' }}></Row>

                                    {simulacao === undefined ||
                                        simulacao === null ||
                                        simulacao === [] ? (
                                        <></>
                                    ) : (
                                        <Row>
                                            <Table striped hover>
                                                <thead align='center'>
                                                    <tr>
                                                        <td>
                                                            <b>#</b>
                                                        </td>
                                                        <td>
                                                            <b>Parcela&nbsp;*</b>
                                                        </td>
                                                        <td>
                                                            <b>Juros</b>
                                                        </td>
                                                        <td>
                                                            <b>Valor Total</b>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody align='center'>
                                                    {simulacao.map((prop, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{prop.qtyInstallments}/{prop.qtyInstallments}</td>
                                                                <td>{`R$ ${prop.installmentValue
                                                                    .toFixed(2)
                                                                    .replace('.', ',')}`}</td>
                                                                <td>{`R$ ${prop.totalInterest
                                                                    .toFixed(2)
                                                                    .replace('.', ',')}`}</td>
                                                                <td>{`R$ ${(prop.installmentValue * prop.qtyInstallments)
                                                                    .toFixed(2)
                                                                    .replace('.', ',')}`}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    )}
                                </div>
                            }
                            legend={
                                <div>
                                    <i>
                                        Fixas (Tabela Price) <br /> * Parcela = (Amortização +
                                        Juros)
                                    </i>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    <span style={{ fontSize: '1.5rem' }}>Selecione um produto e informe um valor para realizar uma simulação.</span>
                </Alert>
            </Snackbar>
        </div>
    );
}
