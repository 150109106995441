import ReactTable from "react-table";
import capitalize from "capitalize-pt-br";
import Spinner from "react-spinner-material";
import api from "../../../../services/api.jsx";
import React, { useState, useEffect } from "react";
import Card from "../../../../components/Card/Card.jsx";
import { getToken } from "../../../../services/autenticacoes.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { Grid, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ToastProvider } from "react-toast-notifications";
import Notifications from "../../../Credenciados/Components/Toast/Toast.jsx";

// * Novo método de criação, adição e atualização de credenciados.
import { Editar } from "./Edit.jsx";
import { Cadastrar } from "./New.jsx";

export default function TableList() {
  const [paginacao, setPagina] = useState({ pagina: 0, dados: [] });
  const [error, setError] = React.useState([]);
  const [data, setData] = useState([]);
  const [canais_only, setcanaisOnly] = useState([]);

  const editar = <Tooltip id="edit_tooltip">Editar</Tooltip>;
  const resetarSenha = <Tooltip id="remove_tooltip">Resetar senha</Tooltip>;
  const gerarContrato = (
    <Tooltip id="remove_tooltip">
      Clique aqui para gerar o contrato deste Credenciado
    </Tooltip>
  );

  useEffect(() => {
    async function buscarDados() {
      try {
        const resposta = await api.get("/administrativo/credenciados", {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });
        if (resposta.data.error) {
          // * Apresenta no console qualquer erro proveniente do consumo da ApiRest
          console.log(resposta.data.error);
          const canais = await api.get("/canais", {
            headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
          });
          setcanaisOnly(canais.data);
        } else {
          setData(resposta.data);
          const canais = await api.get("/canais", {
            headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
          });
          setcanaisOnly(canais.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    buscarDados();
  }, []);

  const downloadListCredenciados = async (fileName) => {
    try {
      const response = await api.get("/administrativo/list-credenciados", {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });
      
      if (response.error) {
        alert(response.message);
      } else {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = `${fileName}`;
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (e) {
      setError([
        {
          message: "Erro ao baixar o arquivo de credenciados!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }
  }

  async function deletarSenha(cnpj_cpf) {
    if (
      window.confirm(
        `Deseja realmente resetar a senha do credenciado: ${cnpj_cpf}?`
      )
    ) {
      try {
        const { data } = await api.delete(
          `/administrativo/credenciado/reset/${String(cnpj_cpf).replace(
            /\D/g,
            ""
          )}`,
          { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
        );
        if (data.error) {
          alert(data.message);
        } else {
          alert(data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      {data.length <= 0 ? (
        <div
          style={{
            backgroundColor: "#FFF",
            justifyContent: "center",
            display: "flex",
            flex: 1,
            height: "800px",
            alignItems: "center",
          }}
        >
          {" "}
          <Spinner
            size={120}
            spinnercolor={"#333"}
            spinnerwidth={2}
            visible={true}
          />
        </div>
      ) : paginacao.pagina === 0 ? (
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title={
                    <>
                      <div style={{ 
                        marginTop: '5px',
                        float: 'left',
                        paddingRight: '20px',
                        borderRight: '2px solid #c5c5c5',
                      }}>
                        Credenciados
                      </div>
                      <Button
                        onClick={() => setPagina({ pagina: 2, dados: [] })}
                        type="submit"
                        bsStyle="success"
                        variant="contained"
                        style={{
                          border: 0,
                          color: "white",
                          borderRadius: 3,
                          fontSize: "14px",
                          marginLeft: '20px',
                          marginBottom: "10px",
                          backgroundColor: `${'#008000'}`,
                          boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                        }}
                      >
                        <i className="fa fa-plus" aria-hidden="true" /> 
                        &nbsp;&nbsp;
                        Novo Credenciado
                      </Button>
                      <Button
                        onClick={() => downloadListCredenciados('credenciados.csv')}                        
                        bsStyle="success"
                        variant="contained"
                        style={{
                          border: 0,
                          color: "white",
                          borderRadius: 3,
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginBottom: "10px",
                          backgroundColor: `${'#008000'}`,
                          boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                        }}
                      >
                        <i className="fa fa-file-text-o" aria-hidden="true" /> 
                        &nbsp;&nbsp;
                        Exportar Arquivo
                      </Button>                                            
                    </>
                  }
                  content={
                    <div>                      
                      <div align="center" style={{ margin: 15 }}>
                        <ReactTable
                          data={data.map((prop, key) => {
                            return {
                              id: key,
                              ativo: `${prop.ativo ? "ATIVO" : "SUSPENSO"}`,
                              razao_social: capitalize(
                                prop.razao_social
                              ).toUpperCase(),
                              nome_fantasia: capitalize(
                                String(prop.nome_fantasia)
                              ).toUpperCase(),
                              cnpj: prop.cnpj_cpf,
                              contato:
                                String(prop.celular).length <= 0 ||
                                prop.celular === null
                                  ? "Solicitar Contato"
                                  : String(prop.celular).length === 11
                                  ? `(${String(prop.celular)[0]}${
                                      String(prop.celular)[1]
                                    }) ${String(prop.celular).slice(2)}`
                                  : prop.celular,
                              acoes: (
                                <div className="actions-center">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={editar}
                                    onClick={() =>
                                      setPagina({ pagina: 1, dados: prop })
                                    }
                                  >
                                    <Button
                                      bsStyle="info"
                                      simple={true}
                                      type="button"
                                      bsSize="sm"
                                    >
                                      <div style={{ fontSize: 20 }}>
                                        <i className="fa fa-edit" />
                                      </div>
                                    </Button>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="top"
                                    overlay={gerarContrato}
                                  >
                                    <Button
                                      bsStyle="danger"
                                      simple={true}
                                      type="button"
                                      bsSize="sm"
                                    >
                                      <div style={{ fontSize: 20 }}>
                                        <i
                                          className="fa fa-file-pdf-o"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </Button>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="top"
                                    overlay={resetarSenha}
                                    onClick={() => deletarSenha(prop.cnpj_cpf)}
                                  >
                                    <Button
                                      bsStyle="success"
                                      simple
                                      type="button"
                                      bsSize="sm"
                                    >
                                      <div style={{ fontSize: 20 }}>
                                        <i className="fa fa-unlock-alt" />
                                      </div>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              ),
                            };
                          })}
                          filterable
                          columns={[
                            {
                              Header: "Status",
                              accessor: "ativo",
                              filterMethod: (filter, row) => {
                                const filterValue = filter.value.toLowerCase();
                                const rowValue = String(row[filter.id]).toLowerCase();
                                return rowValue.includes(filterValue);
                              },
                            },
                            {
                              Header: "RAZAO SOCIAL",
                              accessor: "razao_social",
                              filterMethod: (filter, row) => {
                                const filterValue = filter.value.toLowerCase();
                                const rowValue = String(row[filter.id]).toLowerCase();
                                return rowValue.includes(filterValue);
                              },
                            },
                            {
                              Header: "NAME FANTASIA",
                              accessor: "nome_fantasia",
                              filterMethod: (filter, row) => {
                                const filterValue = filter.value.toLowerCase();
                                const rowValue = String(row[filter.id]).toLowerCase();
                                return rowValue.includes(filterValue);
                              },
                            },
                            {
                              Header: "CNPJ",
                              accessor: "cnpj",
                            },
                            {
                              Header: "CONTATO",
                              accessor: "contato",
                              filterMethod: (filter, row) => {
                                const filterValue = filter.value.toLowerCase();
                                const rowValue = String(row[filter.id]).toLowerCase();
                                return rowValue.includes(filterValue);
                              },
                            },
                            {
                              Header: "",
                              accessor: "acoes",
                              filterable: false,
                              sortable: false,
                            },
                          ]}
                          defaultPageSize={30}
                          className="-striped -highlight text-center"
                        />
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      ) : paginacao.pagina === 1 ? (
        <Editar
          dados_credenciados={paginacao.dados}
          canais={canais_only}
          voltar={() => setPagina({ pagina: 0, dados: [] })}
        />
      ) : (
        <Cadastrar
          canais={canais_only}
          dados_credenciados={{
            ativo: true,
            status: true,

            razao_social: "",
            nome_fantasia: "",
            cnpj_cpf: null,
            incricao_municipal: null,
            incricao_estadual: null,

            email: "",
            proprietario: "",
            telefone: "",
            celular: "",
            whatsapp: false,
            anotacoes: "",
            canais_ativos: null,

            dia_fechamento: null,
            parcelamento: true,

            endereco: "",
            numero: "",
            bairro: "",
            cidade: "",
            cep: null,
            estado: "",

            taxa: 0,

            agencia: "",
            numero_banco: "",
            banco: "",
            conta: "",
            cpf_titular: "",
            titular: "",
            modalidade: "",
          }}
          voltar={() => setPagina({ pagina: 0, dados: [] })}
        />
      )}
    </>
  );
}
