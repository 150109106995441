const DatePikerConfig = {
  months: [
    "Janeiro (01)",
    "Fevereiro (02)",
    "Março (03)",
    "Abril (04)",
    "Maio (05)",
    "Junho (06)",
    "Julho (07)",
    "Agosto (08)",
    "Setembro (09)",
    "Outubro (10)",
    "Novembro (11)",
    "Dezembro (12)",
  ],
  week: ["Dom", "Seg", "Ter", "Quarta", "Qui", "Sex", "Sáb"],
};

const DateZone = "America/Sao_paulo";

const TimeZone = "dd/MM/yyyy HH:mm:ss";

module.exports = { DatePikerConfig, DateZone, TimeZone };
