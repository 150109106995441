import styled from 'styled-components';
import { Button } from "react-bootstrap";

export const CustomButton = styled(Button)`
    color: #008000;
    margin-bottom: 20px;
    border: 2px solid #008000;
    background-color: #FFFFFF;
    margin-left: ${window.innerWidth < 342 ? '0px' : '20px'};
    
    :hover {
      color: #FFFFFF;
      border: 2px solid #008000;
      background-color: #008000;
    }

    :focus {
      color: #FFFFFF;
      border: 2px solid #0f770f;
      background-color: #0f770f;
    }
`;
