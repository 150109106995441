import styled from 'styled-components';
import backgound from '../../../../../assets/img/empty_result.png'

export const Content = styled.div`
    flex: 1; 
    height: 300px; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column ;
    background-color: #FFF;
`;

export const Image = styled.div`
    height: 300px;
    width: 300px;
    background: url(${backgound});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

export const Text = styled.div`
    margin: 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
`;
