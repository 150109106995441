import React from "react";
import { useToasts } from "react-toast-notifications";

export default function Notifications({ msg, clear }) {
  const { addToast } = useToasts();

  if (msg.length !== 0) {
    msg.forEach((e) => {
      addToast(e.message, {
        appearance: e.appearance,
        autoDismiss: e.auto_dismiss,
      });
    });

    clear();
  }

  return <></>;
}
