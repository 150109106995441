export const TOKEN_KEY = "Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const howisadmin = (admin) => {
  localStorage.setItem("Admin", admin._id);
  localStorage.setItem("Name", admin.nome);
};
export const logout = () => {
  localStorage.clear();
};

export const parseJwt = () => {
  const token = getToken();
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const checkPermissoes = (permissao, acao, chave) => {  
  const permissoes = parseJwt().grupo.permissoes.filter( p => Object.keys(p)[0] === permissao )[0][permissao];

  if ( permissoes === undefined || permissoes.length === 0 ) return false;
  
  var exceto = [];
  var apenas = [];

  const arrayAcoes = permissoes.split(',').map( ac => {
    if ( ac.indexOf(':') > 0 ) {
      if ( ac.split(':')[0] === acao ) {
        if ( ac.indexOf('APENAS') > 0 ) {
          apenas = ac.split(':').filter( ex => ex !== acao && ex !== 'APENAS' ).map( ap => ap );
        }
        if ( ac.indexOf('EXCETO') > 0 ) {
          exceto = ac.split(':').filter( ex => ex !== acao && ex !== 'EXCETO' ).map( ex => ex );
        }
  
        return ac.split(':')[0];
      }
    } else {
      if (ac === acao ) return ac ;
    }
  })

  var onExcecao = false;
  var onApenas = true;

  if ( chave ) {
    if ( exceto.length > 0 ) {
      onExcecao = exceto.includes(chave);
    }

    if ( apenas.length > 0 ) {
      onApenas = apenas.includes(chave);
    }
  }
  return ( arrayAcoes.includes(acao) && ! onExcecao && onApenas );
}