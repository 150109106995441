import React from "react";
import Loading from '../../Components/Loading';
import MyAPI from "../../../../connections/Api.jsx";
import Card from "../../../../components/Card/Card.jsx";
import Notifications from "../../Components/Toast/Toast.jsx";

import { ToastProvider } from "react-toast-notifications";
import { Formatacao } from "../../../../utils/Functions/Formatacoes.jsx";
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";

export default function Companie() {
    const [error, setError] = React.useState([]);
    const [companie, setCompanie] = React.useState(null);

    React.useEffect(() => {
        async function fetchData() {
            try {
                const response = await MyAPI.getInformation();

                setCompanie(response.data);
            } catch (e) {
                setError([{ message: "Não foi possível buscar os dados de sua empresa ou seus funcionários!", appearance: "error", auto_dismiss: true }]);
            }
        }
        fetchData();
    }, []);

    if (companie === null) { return <Loading /> }

    return (
        <div className="content">
            <ToastProvider>
                <Notifications msg={error} clear={() => setError([])} />
            </ToastProvider>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={
                                <>
                                    Informações da Empresa
                                    <br />
                                    <br />
                                </>
                            }
                            content={
                                <form>
                                    <Row>
                                        <Col md={1}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Registro
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Registro"
                                                    disabled={true}
                                                    defaultValue={companie.registro}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Razão Social
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Razão Social"
                                                    disabled={true}
                                                    defaultValue={companie.razao_social}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Nome Fantasia
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Nome Fantasia"
                                                    disabled={true}
                                                    defaultValue={companie.nome_fantasia}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Escrição est. ou mun.
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Escrição est. ou mun."
                                                    disabled={true}
                                                    defaultValue={companie.incricao_estadual}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    CNPJ
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="CNPJ"
                                                    disabled={true}
                                                    defaultValue={companie.cnpj}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Proprietário
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Proprietário"
                                                    disabled={true}
                                                    defaultValue={companie.proprietario}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Dia de pagamento
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Dia de pagamento"
                                                    disabled={true}
                                                    defaultValue={`Dia ${("0" + companie.dia_pagamento).slice(-2)}`}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Email
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Email"
                                                    disabled={true}
                                                    defaultValue={companie.email}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Telefone
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    disabled={true}
                                                    bsClass="form-control"
                                                    placeholder="Telefone"
                                                    value={Formatacao("TEL", companie.telefone)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Celular
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    disabled={true}
                                                    bsClass="form-control"
                                                    placeholder="Celular"
                                                    value={Formatacao("CEL", companie.celular)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={1} align="Center">
                                            <FormGroup>
                                                <ControlLabel
                                                    style={{ color: "#000", textAlign: "center" }}
                                                >
                                                    Whatsapp
                                                </ControlLabel>
                                                <div style={{ marginTop: "10px", textAlign: "center" }}>
                                                    <input
                                                        disabled={true}
                                                        type="checkbox"
                                                        name="q1_myOptions[]"
                                                        id="input_1_4"
                                                        defaultChecked={companie.whatsapp}
                                                    />
                                                    <label htmlFor="input_1_4"></label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    CEP
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="CEP"
                                                    disabled={true}
                                                    defaultValue={Formatacao('CEP', companie.cep)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Cidade
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Cidade"
                                                    disabled={true}
                                                    defaultValue={companie.cidade}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Estado
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Estado"
                                                    disabled={true}
                                                    defaultValue={companie.estado}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Endereço
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Endereço"
                                                    disabled={true}
                                                    defaultValue={companie.endereco}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={1}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Número
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Número"
                                                    disabled={true}
                                                    defaultValue={companie.numero}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <ControlLabel style={{ color: "#000" }}>
                                                    Bairro
                                                </ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    bsClass="form-control"
                                                    placeholder="Bairro"
                                                    disabled={true}
                                                    defaultValue={companie.bairro}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {companie.servicos_autorizados !== undefined ? (
                                        <Card
                                            title="Canais liberados ao funcionários"
                                            content={companie.servicos_autorizados.map(
                                                (item, key) => (
                                                    <Row key={key}>
                                                        <Col md={2}>
                                                            <FormGroup>
                                                                <ControlLabel style={{ color: "#000" }}>
                                                                    Nome do canal
                                                                </ControlLabel>
                                                                <FormControl
                                                                    type="text"
                                                                    bsClass="form-control"
                                                                    placeholder="Nome do Serviço"
                                                                    value={item.canal}
                                                                    readOnly
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={1}>
                                                            <FormGroup align="center">
                                                                <ControlLabel style={{ color: "#000" }}>
                                                                    Parcelas em até
                                                                </ControlLabel>
                                                                <FormControl
                                                                    type="text"
                                                                    style={{ textAlign: 'center' }}
                                                                    bsClass="form-control"
                                                                    placeholder="Parcelas"
                                                                    disabled={true}
                                                                    defaultValue={`${item.parcelas_max}x`}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={1}>
                                                            <FormGroup align="center">
                                                                <ControlLabel style={{ color: "#000" }}>
                                                                    Consumo Máx.
                                                                </ControlLabel>
                                                                <FormControl
                                                                    style={{ textAlign: 'center' }}
                                                                    type="text"
                                                                    bsClass="form-control"
                                                                    placeholder="Parcelas"
                                                                    disabled={true}
                                                                    defaultValue={Number(item.limite_consumo).toLocaleString("pt-br", {
                                                                        style: "currency",
                                                                        currency: "BRL",
                                                                    })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                )
                                            )}
                                        />
                                    ) : (
                                        <Card
                                            title="Canais liberados pela empresa"
                                            content={
                                                <Row>
                                                    <Col md={12}>
                                                        Não encontramos nenhum canal no cadastro deste funcionário!
                                                    </Col>
                                                </Row>
                                            }
                                        />
                                    )}
                                </form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};
