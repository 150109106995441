import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import { makeStyles } from "@material-ui/core/styles";

//#AVISO: Página em desenvolvimento

import image from "./../../../assets/img/icon_maintenance.png";

export default function Configuracoes() {
  const classes = useStyles();

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div style={{ height: window.innerHeight * 0.4 }}>
                  <a
                    href="https://www.ramicard.com.br/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#000" }}
                  >
                    <div className={classes.background} />
                    <div className={classes.notification}>
                      Estamos trabalhando no desenvolvimento desta ferramenta...
                    </div>
                  </a>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${image})`,
    height: "80px",
    width: "80px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    margin: "auto",
    marginTop: "17%",
  },

  notification: {
    fontSize: 20,
    flex: 1,
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "17%",
  },
});
