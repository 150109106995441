import React from "react";
import moment from "moment";
import MyAPI from "../../../../../connections/Api.jsx";
import Card from "../../../../../components/Card/Card";
import DatePicker from "react-16-bootstrap-date-picker";
import Notifications from "../../../Components/Toast.jsx";
import Button from "../../../../../components/CustomButton/CustomButton.jsx";

import { MultiSelect } from "primereact/multiselect";
import { ToastProvider } from "react-toast-notifications";
import { Grid, Row, Col, FormGroup, ControlLabel, Table } from "react-bootstrap";
import { DatePikerConfig } from "../../../../../utils/Abstractions/Variables.jsx";
import { anyIso, isoShortDate } from "../../../../../utils/Abstractions/Functions.jsx";
import { ReceberEmpresasXlsx } from "../../../../../services/Excel/Exportacoes-xlsx.jsx";

import * as S from './Styles';

export default function Analiticos({ goTo }) {
  const [data, setData] = React.useState([]);
  const [selected, setSelect] = React.useState([]);
  const [selectedSituacoes, setSelectSituacoes] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [finalDate, setFinalDate] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [initialDate, setInitialDate] = React.useState("");
  const [onSelectedList, setOnSelectedList] = React.useState(false);
  const [onSelectedSituacoes, setOnSelectedSituacoes] = React.useState(false);

  const [selectedFechamento, setSelectFechamento] = React.useState([]);
  const [onSelectedFechamento, setOnSelectedFechamento] = React.useState(false);

  const cabecalhos = [
    "CREDENCIADOS",
    "EMPRESA",
    "FUNCIONARIO",
    "AUTO",
    "AUTORIZACAO",
    "VENCIMENTO",
    "P",
    "T",
    "VALOR",
  ];

  const cartoes = [
    { name: "Alimentação", code: "AL" },
    { name: "Produtos", code: "PR" },
    { name: "Serviços", code: "SE" },
    { name: "Combustível", code: "CO" }
  ];

  const situacoes = [
    { name: "Aberta", code: "Aberta" },
    { name: "Adiantada", code: "Adiantada" },
    { name: "Cancelado", code: "Cancelado" },
    { name: "Fechado", code: "Fechado" },
    { name: "Pendente", code: "Pendente" },
    { name: "Rescisao", code: "Rescisao" }
  ];

  const fechamentos = [
    { fechamento: "Dia 25", dia: "25" },
    { fechamento: "Dia 24", dia: "24" },
  ];

  const TaxaAlimentacao = 0.05;

  const fetchData = async () => {
    let errors = 0

    if (selected.length === 0) {
      errors++
      setError([{ message: "Selecione pelo menos um canal!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (finalDate === '') {
      errors++
      setError([{ message: "Insira o período final!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (initialDate === '') {
      errors++
      setError([{ message: "Insira o período inicial!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    try {
      if (errors === 0) {
        setLoading(true)
        const response = await MyAPI.getVoucherList({ initialDate, finalDate, channels: selected.map(e => e.name), situacoes: selectedSituacoes.map(e => e.name) });

        const closedDays = selectedFechamento.map(e => Number(e.dia));

        if (closedDays.length > 0) {
          setData(response.data.filter(e => closedDays.includes(e.empresa_responsavel_id.dia_fechamento)));
        } else {
          setData(response.data)
        }

        setLoading(false)
      }
    } catch (e) {
      setError([{ message: "Não foi possível buscar os dados de sua empresa!", appearance: "error", auto_dismiss: true }]);
    }
  }

  const MesVendido = (data, parcelaTotal, parcelaAtual) =>
    moment(data, "DD/MM/YYYY HH:mm:ss")
      .add("M", -(parcelaAtual - 1))
      .format("DD/MM/YYYY HH:mm:ss");

  const MesFuturo = (data, parcelaTotal, parcelaAtual) =>
    moment(data, "DD/MM/YYYY HH:mm:ss")
      .add("M", +(parcelaTotal - parcelaAtual))
      .format("DD/MM/YYYY");

  const valorTotalEmpresas = () => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };
    const ValorFinal = data
      .map((e) =>
        e.canal === "Alimentação" || e.canal === "Combustível"
          ? CartaoAlimentacao(
            e.taxa_funcionario
              ? e.valor * e.taxa_funcionario
              : e.valor / e.parcelas
          )
          : e.taxa_funcionario
            ? e.valor * e.taxa_funcionario
            : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const GerarArquivo = () => {
    let dataSet = [
      {
        columns: [
          {
            title: "CREDENCIADOS",
            width: { wpx: 90 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "EMPRESA",
            width: { wch: 60 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "FUNCIONARIO",
            width: { wpx: 140 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "AUTO",
            width: { wpx: 125 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "AUTORIZAÇÃO",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "VENCIMENTO",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "P",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "T",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "VALOR",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
        ],
        data: [],
      },
    ];

    Organizador(data)
      .map((item) =>
        item
          ? dataSet[0].data.push([
            {
              value:
                item.credenciado_id.registro === undefined
                  ? "-"
                  : item.credenciado_id.registro,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.empresa_responsavel_id.nome_fantasia,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.usuario_id.nome,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.canal,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: MesVendido(
                item.horario_confirmacao,
                item.parcelas,
                item.parcela_atual
              ),
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: MesFuturo(
                item.horario_confirmacao,
                item.parcelas,
                item.parcela_atual
              ),
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.parcela_atual,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.parcelas,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: Number(
                item.taxa_funcionario
                  ? item.canal === "Alimentação" ||
                    item.canal === "Combustível"
                    ? AdicionarTaxa(
                      item.valor * Number(item.taxa_funcionario)
                    )
                    : item.valor * Number(item.taxa_funcionario)
                  : item.canal === "Alimentação" ||
                    item.canal === "Combustível"
                    ? AdicionarTaxa(item.valor / item.parcelas)
                    : item.valor / item.parcelas
              ),
              style: {
                numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
          ])
          : false
      )
      .filter((e) => e !== false);

    dataSet[0].data.push([
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      { value: " " },
      {
        value: "Total à Receber: ",
        style: {
          font: { sz: "14", bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        value: Number(valorTotalEmpresas()),
        style: {
          numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "14", bold: true },
          alignment: { horizontal: "center" },
        },
      },
    ]);

    return dataSet;
  };

  const MoedaBR = (valor) =>
    Number(valor).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });

  const Organizador = (Item) =>
    Item.sort((a, b) =>
      b.empresa_responsavel_id.nome_fantasia <
        a.empresa_responsavel_id.nome_fantasia
        ? 1
        : a.empresa_responsavel_id.nome_fantasia <
          b.empresa_responsavel_id.nome_fantasia
          ? -1
          : 0
    );

  const AdicionarTaxa = (valor) => valor + valor * TaxaAlimentacao;

  return (
    <div className="content" style={{ minHeight: "850px" }}>
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12} style={{ marginTop: 25 }}>
            <Card
              title={
                <>
                  <Button
                    onClick={() => goTo("Menu")}
                    style={{ backgroundColor: "#008000", color: "#FFF" }}
                    simple={true}
                    type="button"
                    bsSize="sm"
                  >
                    {" "}
                    <i className="fa fa-bars" aria-hidden="true" /> Menu
                  </Button>
                  <br />
                  <p align="center" style={{ fontSize: 20 }}>
                    Relatório de recebimento das Empresas
                  </p>
                </>
              }
              content={
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período inicial
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(initialDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setInitialDate(isoShortDate(e, "dd/MM/yyyy"))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período Final
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(finalDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setFinalDate(isoShortDate(e, "dd/MM/yyyy"))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Cartões
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedList(false)}
                      onMouseOver={() => setOnSelectedList(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selected}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedList ? 0.3 : 1,
                        }}
                        options={cartoes}
                        placeholder={`Cartões Ramicard`}
                        onChange={(e) => setSelect(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Situação
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedSituacoes(false)}
                      onMouseOver={() => setOnSelectedSituacoes(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selectedSituacoes}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedSituacoes ? 0.3 : 1,
                        }}
                        options={situacoes}
                        placeholder={`Situações das Guias`}
                        onChange={(e) => setSelectSituacoes(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Dias de Fechamento
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedFechamento(false)}
                      onMouseOver={() => setOnSelectedFechamento(true)}
                    >
                      <MultiSelect
                        optionLabel="fechamento"
                        value={selectedFechamento}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedFechamento ? 0.3 : 1,
                        }}
                        options={fechamentos}
                        placeholder={`Fechamentos`}
                        onChange={(e) => setSelectFechamento(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <S.WarapperButtons>
                      <Button
                        onClick={() => fetchData()}
                        style={{
                          backgroundColor: "#008000",
                          color: "#FFF",
                          marginRight: 15,
                          fontSize: 16,
                          width: 116,
                        }}
                        simple
                        type="button"
                        bsSize="sm"
                      >
                        <i className="fa fa-search" aria-hidden="true" />{" "}
                        Pesquisar
                      </Button>
                      {data.length > 0 ? <ReceberEmpresasXlsx buttonName="Baixar xlsx" files={GerarArquivo()} /> : null}
                    </S.WarapperButtons>
                  </Col>
                </Row>
              }
            />

            <Card
              content={
                <div>
                  <div align="center" style={{ margin: 20 }}>
                    <Table striped hover>
                      <thead>
                        <tr>
                          {cabecalhos.map((prop, key) => {
                            return (
                              <th
                                key={key}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "#000000",
                                }}
                              >
                                {prop}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody align="center">
                        {!isLoading ? (
                          Organizador(data).map((prop, key) => {
                            return (
                              <tr key={key}>
                                <td>{`${prop.credenciado_id.registro === undefined
                                  ? "-"
                                  : prop.credenciado_id.registro
                                  }`}</td>
                                <td>{`${prop.empresa_responsavel_id.nome_fantasia}`}</td>
                                <td>{`${prop.usuario_id.nome}`}</td>
                                <td>{prop.canal}</td>
                                <td>
                                  {MesVendido(
                                    prop.horario_confirmacao,
                                    prop.parcelas,
                                    prop.parcela_atual
                                  )}
                                </td>
                                <td>
                                  {prop.horario_confirmacao}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {prop.parcela_atual}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {prop.parcelas}
                                </td>
                                <td style={{ textAlign: "center" }}>{`${MoedaBR(
                                  (prop.taxa_funcionario
                                    ? prop.canal === "Alimentação" ||
                                      prop.canal === "Combustível"
                                      ? AdicionarTaxa(
                                        prop.valor *
                                        Number(prop.taxa_funcionario)
                                      )
                                      : prop.valor *
                                      Number(prop.taxa_funcionario)
                                    : prop.canal === "Alimentação" ||
                                      prop.canal === "Combustível"
                                      ? AdicionarTaxa(prop.valor / prop.parcelas)
                                      : prop.valor / prop.parcelas
                                  ).toFixed(2)
                                )}`}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        )}
                        {!isLoading ? (
                          <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              Total Geral:
                            </td>
                            <td />
                            <td />
                            <td style={{ fontWeight: "bold" }}>
                              {data ? MoedaBR(valorTotalEmpresas()) : "-"}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td />
                            <td
                              style={{ fontWeight: "bold", textAlign: "right" }}
                            >
                              Total Geral:
                            </td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                            <td style={{ fontWeight: "bold" }}>-</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
