import { Card } from "../../../../components/Card/Card.jsx";
import api from "../../../../services/api.jsx";
import Capitalize from "capitalize-pt-br";
import React, { useState, useEffect } from "react";
import Loading from '../../Components/Loading';
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { getToken } from "../../../../services/autenticacoes.jsx";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { Dropdown } from 'primereact/dropdown';

export default function TableList() {
  const [dados_credenciado, setDados_credenciado] = useState([]);
  const [parcelasdoproduto, setparcelasdoproduto] = useState(0);
  const [jurosapartir, setjurosapartir] = useState(0);
  const [nomedoproduto, setnomedoproduto] = useState("");
  const [taxadoproduto, settaxadoproduto] = useState(0);
  const [taxaparcelamento, settaxaparcelamento] = useState(0);
  const [usaLimiteCanal, setusalimitecanal] = useState(false);
  const [limite, setlimite] = useState(0);
  const [limiteparcela, setlimiteparcela] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [faixasParcelamento, setFaixasParcelamento] = useState([]);
  const [faixaSelecionada, setFaixaSelecionada] = useState('');

  useEffect(() => {
    listarCredenciados();	
    loadFaixasParcelamento();
  }, [])
  
  useEffect(() => {
    if ( refresh ) {
      listarCredenciados();	
      loadFaixasParcelamento();
      setRefresh(false)
    }
  }, [refresh])

  async function loadFaixasParcelamento() {
    try {
      const response = await api.get("/layers", {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });

      if ( Array.isArray(response.data) ) {
        setFaixasParcelamento(response.data);
      }

    } catch (error) {
      console.log(String(error));
    }  
  };

  async function listarCredenciados() {
    try {
      const response = await api.get("/canais", {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });
      setDados_credenciado(response.data);
    } catch (error) {
      console.log(String(error));
    }
  };


  const usarLimiteCanal = (usarLimite) => {      
    setusalimitecanal(usarLimite)
    setRefresh(true)
  }

  const faixaDoCanal = (idFaixa) => {
    let idx = faixasParcelamento.findIndex( faixa => faixa._id === idFaixa )
    let faixa;
    if ( idx < 0 ) {
      faixa = faixasParcelamento.find( faixa => faixa.faixa_padrao === true )            
    } else {
      faixa = faixasParcelamento[idx]
    }

    return faixa !== undefined  ? faixa['nome_faixa'] : ''
  }

  async function Cadastrar(event) {
    event.preventDefault();
    
    const valorTaxa = taxadoproduto <= 0 ? 0 : taxadoproduto;
    const valorTaxaParcelamento = taxaparcelamento <= 0 ? 0 : taxaparcelamento;
    const valorParcelas = parcelasdoproduto <= 0 ? 1 : parcelasdoproduto;
    const valorjurosapartir = jurosapartir <= 0 ? 1 : jurosapartir;
    const limiteDoCanal = limite <=0 ? 0 : limite;
    const limiteParcelas = limiteparcela <=0 ? 0 : limiteparcela;    
    const layer_id = faixaSelecionada ? faixaSelecionada._id : faixasParcelamento[0]._id;

    try {
      const DATA = await api.post(
        "/canal/cadastro",
        {
          canal: Capitalize(nomedoproduto),
          taxa: valorTaxa,
          parcelas_max: valorParcelas,
          juros_a_partir: valorjurosapartir,
          taxa_parcelamento: valorTaxaParcelamento,
          usar_limite_canal: usaLimiteCanal,
          limite_consumo: limiteDoCanal,
          limite_parcela: limiteParcelas,
          layer_id
        },
        { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (DATA.data.error) {
        alert(DATA.data.error);
      } else {
        window.location.reload();
      }
    } catch (error) {
      alert("Ops! Sessão expirada ou não exite canais cadastrados no sistema!");
    }
  }

  async function Deletar(canalSelecionado) {
    try {
      await api.delete(`/canal/${canalSelecionado}`, {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });
      window.location.reload();
    } catch (error) {
      alert("Ops! Sessão expirada ou não exite canais cadastrados no sistema!");
    }
  }

  if (dados_credenciado.length === 0) { return <Loading /> }

  return (
    <div className="services" style={{ marginTop: "10px" }}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Cadastrar Canal"
              content={
                <div>
                  <form onSubmit={Cadastrar}>
                    <Row>
                      <Col md={2}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Nome do Serviço
                          </ControlLabel>
                          <FormControl
                            type="text"
                            autoFocus
                            required={true}
                            bsClass="form-control"
                            placeholder="Nome do Serviço"
                            onChange={(event) =>
                              setnomedoproduto(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Tx Serviço %
                          </ControlLabel>
                          <FormControl
                            type="text"
                            required={true}
                            bsClass="form-control"
                            placeholder="Taxa do Serviço"
                            defaultValue={0}
                            onChange={(event) =>
                              settaxadoproduto(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Tx Parcelamento %
                          </ControlLabel>
                          <FormControl
                            type="text"
                            required={true}
                            bsClass="form-control"
                            placeholder="Taxa Parcelamento"
                            defaultValue={0}
                            onChange={(event) =>
                              settaxaparcelamento(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Max. Parcelas
                          </ControlLabel>
                          <FormControl
                            type="number"
                            required={true}
                            bsClass="form-control"
                            placeholder="Parcelas"
                            defaultValue={1}
                            onChange={(event) =>
                              setparcelasdoproduto(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Juros a Partir
                          </ControlLabel>
                          <FormControl
                            type="number"
                            required={true}
                            bsClass="form-control"
                            placeholder="Juros a Partir"
                            defaultValue={1}
                            onChange={(event) =>
                              setjurosapartir(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                            <ControlLabel style={{ color: '#000' }}>Limite Canal?</ControlLabel>
                            <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                              <input type='checkbox' onClick={ (event) => { usarLimiteCanal(event.target.checked) }} />
                            </div>
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Limite R$
                          </ControlLabel>
                          <FormControl
                            type="number"
                            required={true}
                            bsClass="form-control"
                            placeholder="Limite do Canal"
                            disabled={!usaLimiteCanal}
                            defaultValue={1}
                            onChange={(event) =>
                              setlimite(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Limite Parcela R$
                          </ControlLabel>
                          <FormControl
                            type="number"
                            required={true}
                            bsClass="form-control"
                            placeholder="Limite da Parcela"
                            disabled={!usaLimiteCanal}
                            defaultValue={1}
                            onChange={(event) =>
                              setlimiteparcela(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Faixas de Parcelamento
                          </ControlLabel>
                          <Dropdown
                            optionLabel='nome_faixa'
                            placeholder='Faixas de Parcelamento'
                            value={faixaSelecionada}
                            options={faixasParcelamento}
                            onChange={(e) => setFaixaSelecionada(e.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Button
                            type="submit"
                            variant="contained"
                            bsStyle="info"
                            style={{
                              marginTop: "26px",
                              marginLeft: "10px",
                              background: "rgba(35,142,35, .8)",
                              border: 0,
                              borderRadius: 3,
                              boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                              color: "white",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            Cadastrar
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ height: "30px" }}></Row>
                    {dados_credenciado.length > 0 ? (
                      <Card
                        content={dados_credenciado.map((item, key) => (
                          <Row key={key}>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Nome do Serviço
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Nome do Serviço"
                                  value={item.canal}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Tx do Serviço %
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Taxa do Serviço"
                                  value={item.taxa}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Tx Parcel. %
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Taxa Parcelamento"
                                  value={item.taxa_parcelamento}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Max. Parcelas
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Parcelas"
                                  value={item.parcelas_max}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Juros a Partir
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Juros a Partir"
                                  value={item.juros_a_partir}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                  <ControlLabel style={{ color: '#000' }}>Limite Canal?</ControlLabel>
                                  <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                                    <input type='checkbox' disabled={true} checked={item.usar_limite_canal} />
                                  </div>
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Limite Canal R$
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Limite Canal"
                                  value={item.limite_consumo}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Limite Parcelas R$
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Limite Prcela"
                                  value={item.limite_parcela}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Faixa Parcelamento
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Limite Prcela"
                                  value={ faixaDoCanal(item.layer_id) }
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <Button
                                  onClick={() => Deletar(item._id)}
                                  variant="contained"
                                  bsStyle="info"
                                  style={{
                                    marginTop: "26px",
                                    marginLeft: "10px",
                                    background: "rgba(255,0,0, .8)",
                                    border: 0,
                                    borderRadius: 3,
                                    boxShadow:
                                      "0 1px 1px 1px rgba(0, 0, 0, .2)",
                                    color: "white",
                                    fontSize: "14px",
                                  }}
                                >
                                  {" "}
                                  Remover
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        ))}
                      />
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
