import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import { logoutSystem } from "../../utils/Abstractions/Functions.jsx";

class CompaniesNavbarLinks extends Component {
  render() {
    return (
      <div>
        <Nav pullRight>
          <NavItem eventKey={3} onClick={() => logoutSystem()} href="/login/empresa">
            Sair
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default CompaniesNavbarLinks;
