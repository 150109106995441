import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import capitalize from 'capitalize-pt-br';
import PushModal from '../../Components/PushModal';
import Card from '../../../../components/Card/Card.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';

export default function Notifications({ data }) {
  const headers = ['Título', 'Mensagem', 'tag', 'Imagem Anexada'];

  const [showHide, setShowHide] = useState(false);

  const bodyData = () => {
    const { lastNotifications } = data;

    const getLink = ({ big_picture }) => {
      return big_picture ? (
        <a href={big_picture} target='_blank' rel='noopener noreferrer'>
          Visualizar Imagem
        </a>
      ) : (
        'Nenhuma imagem anexada'
      );
    };

    return lastNotifications.length === 0 ? (
      <tr>
        <td colSpan={headers.length} style={{ fontSize: 16 }}>
          Nenhuma notificação enviada este mês
        </td>
      </tr>
    ) : (
      lastNotifications.map((notification, key) => (
        <tr key={key}>
          <td>{notification?.title || ''}</td>
          <td>{notification?.message || ''}</td>
          <td>
            {capitalize(
              notification?.tags.map((item) => item.value).join(', ')
            ) || 'Envio realizado a todos os usuários'}
          </td>
          <td>{getLink(notification)}</td>
        </tr>
      ))
    );
  };

  return (
    <>
      <PushModal show={showHide} closeModal={() => setShowHide(false)} />
      <Card
        title={
          <>
            Envios Recentes
            <br />
            <br />
            <Button
              style={{
                backgroundColor: '#008000',
                color: 'white',
              }}
              bsStyle='success'
              simple={true}
              type='button'
              onClick={() => setShowHide(true)}
            >
              <i className='fa fa-envelope' aria-hidden='true' /> Nova Mensagem
            </Button>
          </>
        }
        content={
          <div align='left' style={{ margin: 20 }}>
            <Table striped hover>
              <thead>
                <tr>
                  {headers.map((prop, key) => {
                    return (
                      <th
                        key={key}
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {prop}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody align='center'>{bodyData()}</tbody>
            </Table>
          </div>
        }
      />
    </>
  );
}
