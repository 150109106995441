// Toast Message
import ToastMsg from '../../Components/Toast';
import { ToastProvider } from 'react-toast-notifications';
import MyAPI from '../../../../connections/Api.jsx';

import axios from 'axios';
import InputMask from 'react-input-mask';
import React, { useState, useEffect } from 'react';
import img from '../../../../assets/img/search.png';
import Card from '../../../../components/Card/Card.jsx';
import { codigo_e_banco } from '../../../../variables/Variables.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Image,
  FormControl,
} from 'react-bootstrap';

export const Cadastrar = (props) => {
  // Toast Message
  const [error, setError] = useState([]);

  const [ref, setRef] = useState(false);
  const [infos_cep, selInformacoes] = useState([]);
  const [template_value, setTemplateValue] = useState(-1);
  const [bancoSelect, Selecionarbanco] = useState([]);
  const [canaisSelecionados, setCanaisSelecionados] = useState([]);

  async function Cadastrar(event) {
    event.preventDefault();    
    let validations = 0;

    if (props.dados_credenciados.canais_ativos === null) {
      validations++;
      setError([
        ...error,
        {
          message: 'Selecione ao menos um canal a este credenciado!',
          title: 'error',
        },
      ]);
    }

    if (Number(props.dados_credenciados.dia_fechamento) > 31) {
      validations++;
      setError([
        ...error,
        { message: 'Selecione um dia de fechamento válido!', title: 'error' },
      ]);
    }

    if (
      String(props.dados_credenciados.cnpj_cpf).length !== 11 &&
      String(props.dados_credenciados.cnpj_cpf).length !== 14
    ) {
      validations++;
      setError([
        ...error,
        { message: 'CNPJ ou CPF inválido!', title: 'error' },
      ]);
    }

    try {
      if (validations === 0) {
        await MyAPI.postAccredited(props.dados_credenciados);

        setError([
          ...error,
          { message: 'Credenciado atualizado com sucesso!', title: 'success' },
        ]);

        setTimeout(() => window.location.reload(), 2000);
      }
    } catch (e) {
      setError([
        ...error,
        {
          message: 'Ocorreu um erro ao tentar cadastrar este Credenciado!',
          title: 'error',
        },
      ]);
    }
  }

  useEffect(() => {
    setRef(false);
  }, [ref]);

  function localiza(canais) {
    const dado = canaisSelecionados
      .map((element, index) => (element.canal === canais ? index : undefined))
      .filter((item) => item !== undefined);
    return dado[0];
  }

  const selecionar = (canal, taxa, parcelas) => {
    let dado = canaisSelecionados;
    const verifica = dado
      .map((element) => (element.canal === canal ? true : undefined))
      .filter((item) => item !== undefined);

    if (verifica.length > 0) {
      dado.splice(
        dado
          .map((element, index) =>
            element.canal === canal ? index : undefined
          )
          .filter((item) => item !== undefined),
        1
      );
      setCanaisSelecionados(dado);
    } else {
      dado.push({ canal: canal, taxa: taxa, parcelas_max: parcelas });
      setCanaisSelecionados(dado);
    }

    props.dados_credenciados.canais_ativos = dado;
    setRef(true);
  };

  const ativo = (canal) => {
    let dado = canaisSelecionados;
    const verifica = dado
      .map((element) => (element.canal === canal ? true : undefined))
      .filter((item) => item !== undefined);
    return !(verifica.length > 0);
  };

  async function cep(n_cep) {
    try {
      const informacoes = await axios.get(
        `https://viacep.com.br/ws/${n_cep}/json/`
      );
      selInformacoes(informacoes.data);

      props.dados_credenciados.endereco = informacoes.data.logradouro;
      props.dados_credenciados.bairro = informacoes.data.bairro;
      props.dados_credenciados.cidade = informacoes.data.localidade;
      props.dados_credenciados.estado = informacoes.data.uf;
    } catch (err) {
      alert('CEP inválido!', err);
    }
  }

  async function banco(n_banco) {
    try {
      const bancoSelecionado = codigo_e_banco.filter((item) =>
        item.codigo === Number(n_banco) ? item : null
      );
      Selecionarbanco(bancoSelecionado);
      props.dados_credenciados.banco = bancoSelecionado[0].nome;
    } catch (err) {
      alert('Banco não encontrado!');
    }
  }

  const selectTemplate = (value) => {
    const value_now = value.replace(/\D/g, '').length;
    setTemplateValue(
      template_value === value_now ? template_value + 1 : value_now
    );
    return value;
  };

  const notString = (value) => value.replace(/\D/g, '');

  return (
    <div className='content'>
      <Grid fluid>
        <ToastProvider>
          <ToastMsg msg={error} clear={() => setError([])} />
        </ToastProvider>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  Credenciado
                  <br />
                  <br />
                  <Button
                    onClick={props.voltar}
                    style={{
                      backgroundColor: `${'#008000'}`,
                      color: '#FFF',
                      marginBottom: '20px',
                    }}
                    bsStyle='success'
                    simple={true}
                    type='button'
                  >
                    {' '}
                    Voltar
                  </Button>
                </>
              }
              content={
                <form style={{ margin: '10px' }} onSubmit={Cadastrar}>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Razão Social*
                        </ControlLabel>
                        <FormControl
                          type='text'
                          required={true}
                          bsClass='form-control'
                          placeholder='Razão Social'
                          onChange={(event) =>
                            (props.dados_credenciados.razao_social =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nome Fantasia*
                        </ControlLabel>
                        <FormControl
                          type='text'
                          required={true}
                          bsClass='form-control'
                          placeholder='Nome Fantasia'
                          onChange={(event) =>
                            (props.dados_credenciados.nome_fantasia =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Inscrição Estadual
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Inscrição Estadual'
                          onChange={(event) =>
                            (props.dados_credenciados.incricao_estadual =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Inscrição Municipal
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Inscrição Municipal'
                          onChange={(event) =>
                            (props.dados_credenciados.incricao_municipal =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          CPF/CNPJ*
                        </ControlLabel>
                        <InputMask
                          required={true}
                          mask={
                            template_value > 11
                              ? '99.999.999/9999-99'
                              : '999.999.999-99'
                          }
                          className='form-control'
                          placeholder='CPF ou CNPJ'
                          defaultValue={props.dados_credenciados.cnpj_cpf}
                          onChange={(event) =>
                            (props.dados_credenciados.cnpj_cpf = selectTemplate(
                              event.target.value.replace(/\D/g, '')
                            ))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Proprietário
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Proprietário'
                          onChange={(event) =>
                            (props.dados_credenciados.proprietario =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Dia Fechamento*
                        </ControlLabel>
                        <InputMask
                          required={true}
                          mask='99'
                          className='form-control'
                          placeholder='Dia do Fechamento'
                          onChange={(event) =>
                            (props.dados_credenciados.dia_fechamento =
                              notString(event.target.value))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Email
                        </ControlLabel>
                        <FormControl
                          type='email'
                          bsClass='form-control'
                          placeholder='Email'
                          onChange={(event) =>
                            (props.dados_credenciados.email =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Telefone
                        </ControlLabel>
                        <InputMask
                          mask='(99)9999-9999'
                          className='form-control'
                          placeholder='Telefone Fixo'
                          onChange={(event) =>
                            (props.dados_credenciados.telefone =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Celular
                        </ControlLabel>
                        <InputMask
                          mask='(99)99999-9999'
                          className='form-control'
                          placeholder='Celular'
                          onChange={(event) =>
                            (props.dados_credenciados.celular =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          WPP
                        </ControlLabel>
                        <div style={{ marginTop: '+10px', marginLeft: '23px' }}>
                          <input
                            type='checkbox'
                            name='q1_myOptions[]'
                            id='input_1_0'
                            defaultChecked={props.dados_credenciados.whatsapp}
                            onClick={() =>
                              (props.dados_credenciados.whatsapp =
                                !props.dados_credenciados.whatsapp)
                            }
                          />
                          <label htmlFor='input_1_0'></label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          CEP
                        </ControlLabel>
                        <InputMask
                          mask='99999-999'
                          className='form-control'
                          placeholder='CEP'
                          onChange={(event) =>
                            (props.dados_credenciados.cep =
                              event.target.value.replace(/\D/g, ''))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        onClick={() => cep(props.dados_credenciados.cep)}
                        variant='contained'
                        bsStyle='info'
                        style={{
                          border: 0,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '27px',
                          background: 'rgba(35,142,35, .8)',
                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        }}
                      >
                        <Image
                          src={img}
                          style={{ width: '22px', height: '22px' }}
                        ></Image>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Cidade
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Cidade'
                          defaultValue={
                            infos_cep.length <= 0 ? '' : infos_cep.localidade
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.cidade =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Estado
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Estado'
                          defaultValue={
                            infos_cep.length <= 0 ? '' : infos_cep.uf
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.estado =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Endereço
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Endereço'
                          defaultValue={
                            infos_cep.length <= 0 ? '' : infos_cep.logradouro
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.endereco =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Número
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Número'
                          onChange={(event) =>
                            (props.dados_credenciados.numero =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Bairro
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Bairro'
                          defaultValue={
                            infos_cep.length <= 0 ? '' : infos_cep.bairro
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.bairro =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nº Banco
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Número'
                          onChange={(event) =>
                            (props.dados_credenciados.numero_banco =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        onClick={() =>
                          banco(props.dados_credenciados.numero_banco)
                        }
                        variant='contained'
                        bsStyle='info'
                        style={{
                          border: 0,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '27px',
                          background: 'rgba(35,142,35, .8)',
                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        }}
                      >
                        <Image
                          src={img}
                          style={{ width: '22px', height: '22px' }}
                        ></Image>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nome do Banco
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Nome do Banco'
                          defaultValue={
                            bancoSelect.length <= 0 ? '' : bancoSelect[0].nome
                          }
                          onChange={(event) =>
                            (props.dados_credenciados.banco =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Agência
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Agência'
                          onChange={(event) =>
                            (props.dados_credenciados.agencia =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nº Conta
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Número da Conta'
                          onChange={(event) =>
                            (props.dados_credenciados.conta =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          CPF do Titular
                        </ControlLabel>
                        <InputMask
                          mask='999.999.999-99'
                          className='form-control'
                          placeholder='CPF do Titular'
                          onChange={(event) =>
                            (props.dados_credenciados.cpf_titular =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Nome completo do Titular
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Nome do Titular'
                          onChange={(event) =>
                            (props.dados_credenciados.titular =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Modalidade
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Modalidade'
                          onChange={(event) =>
                            (props.dados_credenciados.modalidade =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {props.canais.length > 0 ? (
                    <Card
                      title='Canais de Prestação'
                      content={props.canais.map((item, key) => (
                        <Row key={key}>
                          <Col md={2}>
                            <FormGroup>
                              <ControlLabel style={{ color: '#000' }}>
                                Nome do Serviço
                              </ControlLabel>
                              <FormControl
                                type='text'
                                bsClass='form-control'
                                placeholder='Nome do Serviço'
                                value={item.canal}
                                readOnly
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <ControlLabel style={{ color: '#000' }}>
                                Taxa %
                              </ControlLabel>
                              <FormControl
                                type='number'
                                bsClass='form-control'
                                placeholder='Taxa do Serviço'
                                disabled={ativo(item.canal)}
                                defaultValue={item.taxa}
                                onChange={(event) =>
                                  (canaisSelecionados[
                                    localiza(item.canal)
                                  ].taxa = Number(event.target.value))
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <ControlLabel style={{ color: "#000" }}>
                                Max Parcelas
                              </ControlLabel>
                              <FormControl
                                type='number'
                                bsClass='form-control'
                                placeholder='Parcelas'
                                disabled={ativo(item.canal)}
                                value={item.parcelas_max}
                                onChange={(event) =>
                                  (canaisSelecionados[
                                    localiza(item.canal)
                                  ].parcelas_max = event.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>                          
                          <Col md={1}>
                            <FormGroup>
                              <ControlLabel style={{ color: '#000' }}>
                                Ativo
                              </ControlLabel>
                              <div
                                style={{
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                }}
                              >
                                <input
                                  type='checkbox'
                                  defaultChecked={!ativo(item.canal)}
                                  onClick={() => {
                                    selecionar(
                                      item.canal,
                                      item.taxa,
                                      item.parcelas_max
                                    );
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ))}
                    />
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col md={12}>
                      <FormGroup
                        controlId='formControlsTextarea'
                        style={{ marginBottom: '-30px' }}
                      >
                        <ControlLabel style={{ color: '#000' }}>
                          Anotações
                        </ControlLabel>
                        <FormControl
                          rows='3'
                          componentClass='textarea'
                          bsClass='form-control'
                          placeholder='Anotação'
                          onChange={(event) =>
                            (props.dados_credenciados.anotacoes =
                              event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    type='submit'
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      marginRight: '10px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Cadastrar
                  </Button>
                  <Button
                    onClick={props.voltar}
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Cancelar
                  </Button>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
