import React from "react";
import Capitalize from "capitalize-pt-br";
import Card from "../../../../components/Card/Card.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";

import { Formatacao } from "../../../../utils/Functions/Formatacoes.jsx";
import { FormInputs } from "../../../../components/FormInputs/FormInputs.jsx";
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";

export const Editar = (props) => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  <Button
                    onClick={props.voltar}
                    style={{
                      backgroundColor: `${"#008000"}`,
                      color: "#FFF",
                      marginBottom: "20px",
                    }}
                    bsStyle="success"
                    simple={true}
                    type="button"
                  >
                    {" "}
                    Voltar
                  </Button>
                </>
              }
              content={
                <form>
                  <FormInputs
                    {...props.dados_funcionario}
                    ncols={[
                      "col-md-1",
                      "col-md-3",
                      "col-md-3",
                      "col-md-3",
                      "col-md-2",
                    ]}
                    properties={[
                      {
                        disabled:true,
                        label: "Registro",
                        type: "text",
                        required: true,
                        bsClass: "form-control",
                        placeholder: "Código",
                        defaultValue: props.dados_funcionario.registro,
                      },
                      {
                        disabled:true,
                        label: "Funcionário",
                        type: "text",
                        required: true,
                        bsClass: "form-control",
                        placeholder: "Nome Completo",
                        defaultValue: Capitalize(props.dados_funcionario.nome),
                      },
                      {
                        disabled:true,
                        label: "RG",
                        type: "text",
                        required: true,
                        bsClass: "form-control",
                        placeholder: "RG",
                        defaultValue: props.dados_funcionario.rg,
                      },
                      {
                        disabled:true,
                        label: "CPF",
                        type: "text",
                        required: true,
                        bsClass: "form-control",
                        placeholder: "CPF",
                        value: Formatacao("CPF", props.dados_funcionario.cpf),
                      },
                      {
                        disabled:true,
                        label: "Nascido na data",
                        type: "text",
                        required: true,
                        bsClass: "form-control",
                        placeholder: "Nascido na data",
                        value: Formatacao("DATA", props.dados_funcionario.data_nascimento),
                      },
                    ]}
                  />

                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Email
                        </ControlLabel>
                        <FormControl
                          type="text"
                          bsClass="form-control"
                          placeholder="Email"
                          disabled={true}
                          defaultValue={props.dados_funcionario.email}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Telefone
                        </ControlLabel>
                        <FormControl
                          type="text"
                          disabled={true}
                          bsClass="form-control"
                          placeholder="Telefone"
                          value={Formatacao("TEL", props.dados_funcionario.telefone)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Celular
                        </ControlLabel>
                        <FormControl
                          type="text"
                          disabled={true}
                          bsClass="form-control"
                          placeholder="Celular"
                          value={Formatacao("CEL", props.dados_funcionario.celular)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1} align="Center">
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#000", textAlign: "center" }}
                        >
                          Whatsapp
                        </ControlLabel>
                        <div style={{ marginTop: "10px", textAlign: "center" }}>
                          <input
                            disabled={true}
                            type="checkbox"
                            name="q1_myOptions[]"
                            id="input_1_4"
                            defaultChecked={props.dados_funcionario.whatsapp}
                          />
                          <label htmlFor="input_1_4"></label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Sexo
                        </ControlLabel>
                        <FormControl
                          type="text"
                          disabled={true}
                          bsClass="form-control"
                          placeholder="M/F"
                          defaultValue={
                            props.dados_funcionario.sexo === "F"? "Feminino": props.dados_funcionario.sexo === undefined? "": "Masculino"
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormInputs
                    ncols={[
                      "col-md-2",
                      "col-md-2",
                      "col-md-2",
                      "col-md-2",
                      "col-md-1",
                      "col-md-3",
                    ]}
                    properties={[
                      {
                        disabled:true,
                        label: "CEP",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "CEP",
                        value: props.dados_funcionario.cep,
                      },
                      {
                        disabled:true,
                        label: "Cidade",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Cidade",
                        defaultValue: props.dados_funcionario.cidade,
                      },
                      {
                        disabled:true,
                        label: "Bairro",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Cidade",
                        defaultValue: props.dados_funcionario.bairro,
                      },
                      {
                        disabled:true,
                        label: "UF",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Estado",
                        defaultValue: props.dados_funcionario.estado,
                      },
                      {
                        disabled:true,
                        label: "Nº",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Numero",
                        defaultValue: props.dados_funcionario.numero,
                      },
                      {
                        disabled:true,
                        label: "Endereço",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Endereço",
                        defaultValue: props.dados_funcionario.endereco,
                      },
                    ]}
                  />

                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Limite do Cartão
                        </ControlLabel>
                        <FormControl
                          type="text"
                          disabled={true}
                          bsClass="form-control"
                          placeholder="Limite do Cartão"
                          defaultValue={Number(
                            props.dados_funcionario.ramicard.limite
                          ).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Valor de Rescisão
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type="text"
                          bsClass="form-control"
                          placeholder="Valor de Rescisão"
                          defaultValue="R$ 0,00" //#IMPORTANTE: Adicionar calculo de recisão aqui.
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {props.dados_funcionario.ramicard.canais !== undefined ? (
                    <Card
                      title="Canais liberados pela empresa"
                      content={props.dados_funcionario.ramicard.canais.map(
                        (item, key) => (
                          <Row key={key}>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: "#000" }}>
                                  Nome do canal
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Nome do Serviço"
                                  value={item.canal}
                                  readOnly
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup align="center">
                                <ControlLabel style={{ color: "#000" }}>
                                  Parcelas em até
                                </ControlLabel>
                                <FormControl
                                  type="text"
                                  style={{textAlign: 'center'}}
                                  bsClass="form-control"
                                  placeholder="Parcelas"
                                  disabled={true}
                                  defaultValue={`${item.parcelas_max}x`}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup align="center">
                                <ControlLabel style={{ color: "#000" }}>
                                  Situação
                                </ControlLabel>
                                <FormControl
                                  style={{textAlign: 'center'}}
                                  type="text"
                                  bsClass="form-control"
                                  placeholder="Parcelas"
                                  disabled={true}
                                  defaultValue={item.habilitado ? "Habilitado": "Desabilitado"}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      )}
                    />
                  ) : (
                    <Card
                      title="Canais liberados pela empresa"
                      content={
                        <Row>
                          <Col md={12}>
                            Não encontramos nenhum canal no cadastro deste funcionário!
                          </Col>
                        </Row>
                      }
                    />
                  )}
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
