import React from 'react';
import * as S from './Styles';
import CustomButton from '../Button';
import MyAPI from '../../../../connections/Api.jsx';
import { MultiSelect } from 'primereact/multiselect';
import {
  Modal,
  FormControl,
  ControlLabel,
  FormGroup,
  Col,
  Row,
} from 'react-bootstrap';

export default function PushModal({ closeModal, data, ...props }) {
  const [sended, setSended] = React.useState({});
  const [selected, setSelect] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState('');
  const [showImage, setShowImage] = React.useState(false);
  const [onSelectedList, setOnSelectedList] = React.useState(false);
  const [message, setMessage] = React.useState('Digite sua mensagem');
  const [title, setTitle] = React.useState('Digite o titulo da mensagem');

  const tags = [
    { tagName: 'Funcionários', value: 'user', key: 'type' },
    { tagName: 'Credenciados', value: 'accredited', key: 'type' },
  ];

  const sendMessage = async () => {
    try {
      if (
        title !== 'Digite o titulo da mensagem' &&
        message !== 'Digite sua mensagem'
      ) {
        setSended({});
        let dataSend = { title, message };

        if (showImage) {
          dataSend['img'] = imageUrl;
        }

        if (selected.length > 0) {
          let selectedBuffer = [];

          selected.forEach((item) => {
            selectedBuffer.push({ value: item.value, key: 'type' });
          });

          dataSend['tags'] = selectedBuffer;
        }

        const { data, status } = await MyAPI.sendPush(dataSend);

        if (status === 200) {
          console.log(dataSend);
          console.log(data);

          setTitle('Digite sua mensagem');
          setMessage('Digite o titulo da mensagem');
          setSended({ error: false, message: 'Mensagem enviada com sucesso!' });

          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          setSended({ error: true, message: 'Erro ao enviar notificação!' });
        }
      } else {
        setSended({
          error: true,
          message: 'Certifique-se se preencher os campos Título e Mensagem',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal {...props} centered={true} bsSize='large' style={{ margin: 'auto' }}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Nova Mensagem</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: '600px',
          overflowY: 'scroll',
          whiteSpace: 'nowrap',
        }}
      >
        <Col lg={6}>
          <Row>
            <FormGroup>
              <ControlLabel style={{ color: 'rgba(0,0,0,.5)' }}>
                Título
              </ControlLabel>
              <FormControl
                type='text'
                bsClass='form-control -error'
                placeholder='Título da mensagem'
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <ControlLabel style={{ color: 'rgba(0,0,0,.5)' }}>
                Mensagem
              </ControlLabel>
              <FormControl
                rows='3'
                componentClass='textarea'
                bsClass='form-control'
                placeholder='Sua mensagem'
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <ControlLabel style={{ color: 'rgba(0,0,0,.5)' }}>
                Image
              </ControlLabel>
              <FormControl
                type='text'
                bsClass='form-control'
                placeholder='Exemplo: https://i.imgur.com/YVGwHtX.png'
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <ControlLabel style={{ color: 'rgba(0,0,0,.5)' }}>
                Categorias
              </ControlLabel>
              <div
                onMouseOut={() => setOnSelectedList(false)}
                onMouseOver={() => setOnSelectedList(true)}
              >
                <MultiSelect
                  optionLabel='tagName'
                  value={selected}
                  style={{
                    height: 40,
                    width: '100%',
                    color: 'gray',
                    opacity: !onSelectedList ? 0.3 : 1,
                  }}
                  options={tags}
                  placeholder='Selecione uma tag'
                  onChange={(e) => setSelect(e.value)}
                />
              </div>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <ControlLabel style={{ color: 'rgba(0,0,0,.5)' }}>
                Canal Privado
              </ControlLabel>
              <FormControl
                type='text'
                disabled={true}
                bsClass='form-control'
                placeholder='Digite o CPF do funcionário'
                onChange={() => {}}
              />
            </FormGroup>
          </Row>
          <Row />
        </Col>
        <Col lg={6}>
          <S.Smartphone>
            <S.Content>
              <S.Message>
                <p
                  style={{
                    fontSize: 9,
                    display: 'flex',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <i
                    className='fa fa-bell'
                    style={{ fontWeight: 'normal', color: 'gray' }}
                  />{' '}
                  Ramicard
                  <i
                    className='fa fa-circle'
                    style={{
                      fontSize: 3,
                      color: 'gray',
                      width: 10,
                    }}
                  />
                  agora
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    maxWidth: 270,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {title}
                </p>
                <p
                  style={{
                    fontSize: 10,
                    maxWidth: 270,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {message}
                </p>
                {!!imageUrl && !!showImage && (
                  <img alt='Foto' style={{ height: 90, width: 260 }} src={imageUrl} />
                )}
                <img
                  alt='Foto Omitida'
                  hidden={true}
                  onLoad={() => setShowImage(true)}
                  onError={() => setShowImage(false)}
                  src={imageUrl}
                />
              </S.Message>
            </S.Content>
          </S.Smartphone>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <S.Footer>
          <div style={{ color: sended?.error ? 'red' : 'black' }}>
            {sended?.message || ''}
          </div>
          <CustomButton
            onClick={() => sendMessage()}
            style={{ marginLeft: '15px', marginBottom: '0px' }}
            content='Enviar Mensagem'
          />
        </S.Footer>
      </Modal.Footer>
    </Modal>
  );
}
