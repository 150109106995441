import Card from "../../../../components/Card/Card.jsx";
import api from "../../../../services/api.jsx";
import Capitalize from "capitalize-pt-br";
import React, { useState, useEffect } from "react";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { getToken } from "../../../../services/autenticacoes.jsx";

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,  
  Badge
} from "react-bootstrap";

export default function TableList() {  
  const [dados, setFaixas] = useState([]);
  const [nomeDaFaixa, setNomeDaFaixa] = useState('');
  const [faixaPadrao, setFaixaPadrao] = useState(false);
  const [faixasParcelamento, setFaixasParcelamento] = useState([]);
  
  const [faixaInsertOrdem, setFaixaInsertOrdem] = useState('1');
  const [faixaInsertValorMinimo, setFaixaInsertValorMinimo] = useState('');
  const [faixaInsertValorMaximo, setFaixaInsertValorMaximo] = useState('');
  const [faixaInsertParcelas, setFaixaInsertParcelas] = useState('');

  const [editando, setEditando] = useState(false);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {    
    buscarDados();
  }, []);

  useEffect(() => {   
    if ( refresh ) {
      buscarDados();
      clearInputs();
      setRefresh(false);
    }
  }, [refresh])

  async function clearInputs() {
    setNomeDaFaixa('')
    setFaixaPadrao(false)
    setFaixasParcelamento([])
    setFaixaInsertOrdem('1')
    setFaixaInsertValorMinimo('')
    setFaixaInsertValorMaximo('')
    setFaixaInsertParcelas('') 
  }

  const setAsFaixaPadrao = (faixaPadrao) => {      
    setFaixaPadrao(faixaPadrao);    
  }

  async function buscarDados() {
    try {
      const result = await api.get("/layers", {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });

      if ( Array.isArray(result.data) ) {
        setFaixas(result.data);        
      }
    } catch (error) {
      console.log(String(error));
    }
  }

  async function Cadastrar(event) {
    event.preventDefault();

    try {
      let faixa = {          
        nome_faixa: Capitalize(nomeDaFaixa),
        faixa_padrao: faixaPadrao,
        faixas: faixasParcelamento
      }

      if ( editando ) {
        faixa['_id'] = editando['_id'];
      }

      const DATA = await api.post(
        "/layer/cadastro", faixa ,
        { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (DATA.data.error) {
        alert(DATA.data.error);
      } else {
        setRefresh(true);
      }
    } catch (error) {
      alert("Ops! Sessão expirada ou não exitem faixas cadastradas no sistema!");
    }
  }

  async function Deletar(faixaSelecionada) {    
    try {
      if (window.confirm(`Confirma exclusão da faixa de parcelamento [${dados[faixaSelecionada]['nome_faixa']}]?`)) {
        await api.delete(`/layer/${dados[faixaSelecionada]['_id']}`, {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });
        setRefresh(true);
      }
    } catch (error) {
      alert("Ops! Sessão expirada ou não exitem faixas cadastradas no sistema!");
    }
  }

  const adicionarFaixa = () => {
    const ordem = Number(faixaInsertOrdem);
    const maximo = Number(faixaInsertValorMaximo);    

    const faixas = [...faixasParcelamento, {ordem, "valorMinimo": Number(faixaInsertValorMinimo), "valorMaximo": Number(faixaInsertValorMaximo), "parcelas": Number(faixaInsertParcelas)}];
    let erros = null;

    if ( faixas.length > 1 ) {
      const maximoAnterior = faixas[faixas.length - 2]['valorMaximo'];
      const parcelasAnterior = faixas[faixas.length - 2]['parcelas'];

      if ( Number(faixaInsertValorMinimo) <= maximoAnterior ) {
        alert("Valor mínimo deve ser maior que o máximo da faixa anterior.");
        erros = 1;
      } else if ( Number(faixaInsertParcelas) <= parcelasAnterior ) {
        alert("Numero de parcelas deve ser maior que o da faixa anterior.");
        erros = 2
      }
    }
    
    if ( erros === null ) {
      setFaixasParcelamento(faixas);
    
      setFaixaInsertOrdem(ordem+1);      
      setFaixaInsertValorMinimo(maximo+0.01);
      setFaixaInsertValorMaximo(0);
      setFaixaInsertParcelas(0);
    }
  }

  const removerFaixa = (idx) => {
    const faixas = [...faixasParcelamento]
    faixas.splice(idx, 1);
    faixas.map( (f, i) => f.ordem = i+1 );

    setFaixasParcelamento(faixas);    
  }

  const editarFaixa = (faixa) => {      
    setEditando(faixa);
    setNomeDaFaixa(faixa.nome_faixa);
    setFaixaPadrao(faixa.faixa_padrao);
    setFaixasParcelamento(faixa.faixas);
  }

  return (
    <div className="services" style={{ marginTop: "10px" }}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Faixas de Parcelamento"
              content={
                <div>
                  <form onSubmit={Cadastrar}>
                    <Row>
                      <Col md={8}>
                        <FormGroup>
                          <ControlLabel style={{ color: "#000" }}>
                            Nome da Faixa
                          </ControlLabel>
                          <FormControl
                            type="text"
                            autoFocus
                            required={true}
                            bsClass="form-control"
                            placeholder="Nome da Faixa"
                            value={nomeDaFaixa}
                            onChange={(event) =>
                              setNomeDaFaixa(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                            <ControlLabel style={{ color: '#000' }}>Faixa Padrão?</ControlLabel>
                            <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                              <input 
                                type='checkbox'
                                value={faixaPadrao}
                                onClick={ (event) => { setAsFaixaPadrao(event.target.checked) }} />
                            </div>
                        </FormGroup>
                      </Col>
                      
                      <Col md={2}>
                        <FormGroup>
                          <Button
                            type="submit"
                            variant="contained"
                            bsStyle="info"
                            disabled={(faixasParcelamento.length === 0) || (nomeDaFaixa === '') }
                            style={{
                              marginTop: "26px",
                              marginLeft: "10px",
                              background: "rgba(35,142,35, .8)",
                              border: 0,
                              borderRadius: 3,
                              boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                              color: "white",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            Salvar Faixa
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>

                  <Row style={{ height: "12px" }}></Row>

                  <Row>
                    <Col md={1}>
                        <ControlLabel style={{ color: '#000' }}>Ordem</ControlLabel>
                    </Col>
                    <Col md={2}>
                        <ControlLabel style={{ color: '#000' }}>Valor Mínimo R$</ControlLabel>
                    </Col>
                    <Col md={2}>
                        <ControlLabel style={{ color: '#000' }}>Valor Máximo R$</ControlLabel>
                    </Col>
                    <Col md={2}>
                        <ControlLabel style={{ color: '#000' }}>Parcelas</ControlLabel>
                    </Col>
                    <Col md={2}>
                        <ControlLabel style={{ color: '#000' }}></ControlLabel>
                    </Col>
                  </Row>

                  {faixasParcelamento.length > 0 ? 
                    faixasParcelamento.map((faixa, key) => (
                      <Row key={key}>
                        <Col md={1}>
                          <FormGroup>
                            <FormControl
                              type="number"
                              readOnly
                              bsClass="form-control"
                              placeholder="Ordem"
                              value={faixa.ordem}                                
                            />
                          </FormGroup>
                        </Col>
                        
                        <Col md={2}>
                          <FormGroup>
                            <FormControl
                              type="number"
                              readOnly
                              bsClass="form-control"
                              placeholder=">= R$ 0,00"
                              value={faixa.valorMinimo}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={2}>
                          <FormGroup>
                            <FormControl
                              type="number"
                              readOnly
                              bsClass="form-control"
                              placeholder="<= R$ 0,00"
                              value={faixa.valorMaximo}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={2}>
                          <FormGroup>                            
                            <FormControl
                              type="number"                                
                              readOnly
                              bsClass="form-control"
                              placeholder="Parcela em até"
                              value={faixa.parcelas}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={2}>
                          <FormGroup>
                            <Button                            
                              variant="contained"
                              bsStyle="info"
                              style={{
                                marginLeft: "10px",
                                background: "rgba(255,0,0, .8)",
                                border: 0,
                                borderRadius: 3,
                                boxShadow:
                                  "0 1px 1px 1px rgba(0, 0, 0, .2)",
                                color: "white",
                                fontSize: "14px",
                              }}
                              onClick={() => removerFaixa(key)}
                            >
                              {" "}
                              Remover
                            </Button>
                          </FormGroup>
                        </Col>

                      </Row>
                    )
                  ) : (
                    <></>
                  )}  
                  
                  <Row>
                    <Col md={1}>
                      <FormGroup>
                        <FormControl
                          type="number"
                          autoFocus
                          required={true}
                          bsClass="form-control"
                          placeholder="Ordem"
                          value={faixaInsertOrdem}
                          onChange={(event) => {
                            setFaixaInsertOrdem(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    
                    <Col md={2}>
                      <FormGroup>
                        <FormControl
                          type="number"                            
                          required={true}
                          bsClass="form-control"
                          placeholder=">= R$ 0,00"
                          value={faixaInsertValorMinimo}
                          onChange={(event) => {
                            setFaixaInsertValorMinimo(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <FormControl
                          type="number"
                          required={false}
                          bsClass="form-control"
                          placeholder="<= R$ 0,00"
                          value={faixaInsertValorMaximo}
                          onChange={(event) => {
                            setFaixaInsertValorMaximo(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <FormControl
                          type="number"
                          required={true}
                          bsClass="form-control"
                          placeholder="Parcela em até"
                          value={faixaInsertParcelas}
                          onChange={(event) => {
                            setFaixaInsertParcelas(event.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Button                            
                          variant="contained"
                          bsStyle="info"
                          style={{                              
                            marginLeft: "10px",
                            background: "rgba(35,142,35, .8)",
                            border: 0,
                            borderRadius: 3,
                            boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                            color: "white",
                            fontSize: "14px",
                          }}
                          onClick={() => adicionarFaixa()}
                        >
                          {" "}
                          Incluir
                        </Button>
                      </FormGroup>
                    </Col>

                  </Row>                                      
                </div>
              }
            />
          </Col>
        </Row>
        <Row style={{ height: "20px" }}></Row>
        
        {!dados || dados.length === 0 ? <></> : (
          <Row>
          {dados.map((faixa, key) => (
            <Col md={3} key={key}>
              <Card
                title={
                  <Row md={12}>
                    <Col md={8}>
                      {faixa.nome_faixa}
                    </Col>
                    <Col style={{textAlign: 'right'}} md={4}>
                      {faixa.faixa_padrao ? <Badge bg="primary">PADRÃO</Badge> : <></> }                      
                    </Col>
                  </Row>
                }
                content={faixa.faixas.length && faixa.faixas.length > 0 ? (                
                  faixa.faixas.map((f, idx) => (
                    <div key={idx}>{f.ordem} : De <b>{`R$ ${f.valorMinimo.toFixed(2).replace('.', ',')}`}</b> até <b>{`R$ ${f.valorMaximo.toFixed(2).replace('.', ',')}`}</b> Permitido <b>{f.parcelas}</b> parcelas</div>
                  ))
                  ) : <></>}
                legend={
                  <Row style={{marginTop: 12}} md={12}>
                    <Col md={4}>
                      <Button                            
                        variant="contained"
                        bsStyle="info"
                        style={{                              
                          marginLeft: "10px",
                          background: "rgba(35,142,35, .8)",
                          border: 0,
                          borderRadius: 3,
                          boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                          color: "white",
                          fontSize: "14px",
                        }}
                        onClick={() => editarFaixa(faixa)}
                      >
                        {" "}
                        Editar
                      </Button>
                      
                    </Col>
                    <Col md={4}>
                      <Button                            
                        variant="contained"
                        bsStyle="info"
                        style={{
                          marginLeft: "10px",
                          background: "rgba(255,0,0, .8)",
                          border: 0,
                          borderRadius: 3,
                          boxShadow:
                            "0 1px 1px 1px rgba(0, 0, 0, .2)",
                          color: "white",
                          fontSize: "14px",
                        }}
                        onClick={() => Deletar(key)}
                      >
                        {" "}
                        Excluir
                      </Button>                    
                    </Col>
                  </Row> 
                }
              ></Card>
            </Col>
          ))}
          </Row>          
        )}        
      </Grid>
    </div>
  );
}
