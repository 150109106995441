import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import pdfIcon from '../../../../../assets/img/pdf_icon.png';

export default function RamicashReceipt({closeModal, data, ...props}) {
   const isPdf = () => {
      return (data.split(';')[0].indexOf('pdf') >= 0);
   }

   const download = () => {
      var a = document.createElement("a");
      a.href = data;
      a.download = `ramicash-recibo.${ isPdf ? 'pdf' : 'png' }`;
      a.click();
   }

   return (
      <Modal {...props} centered={`${true}`} style={{ margin: 'auto' }}>
         <Modal.Header closeButton onHide={closeModal}>
            <Modal.Title>
               RAMICASH - Recibo PIX
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <img src={ isPdf ? pdfIcon : data} style={{maxWidth: '100%'}} />
         </Modal.Body>
         <Modal.Footer>
            <Button variant="primary" onClick={() => download()}>Download</Button>
         </Modal.Footer>
      </Modal>
   );
}