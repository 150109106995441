import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import styled from 'styled-components';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import DatePicker from "react-16-bootstrap-date-picker";
// import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "primereact/multiselect";
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import Card from '../../../../../components/Card/Card';
import Api from '../../../../../connections/Api';

const Container = styled.div`
  padding: 20px;
`;

const formatCurrency = (value) => `R$ ${value.toFixed(2).replace('.', ',')}`;
const formatDate = (date) => moment(date).format('DD/MM/YYYY');

const schema = yup.object().shape({
    filtroDataInicio: yup.mixed().nullable().test("is-date", "Data inválida", value => value === null || value instanceof Date),
    filtroDataFim: yup.mixed().nullable().test("is-date", "Data inválida", value => value === null || value instanceof Date),
    filtroCredenciado: yup.array().nullable(),
    filtroChavePix: yup.string().nullable()
});

const PixReport = ({ goTo }) => {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            filtroDataInicio: null,
            filtroDataFim: null,
            filtroCredenciado: [],
            filtroChavePix: ''
        }
    });

    const [credenciados, setCredenciados] = useState([]);
    const [transacoes, setTransacoes] = useState([]);
    const [dadosFiltrados, setDadosFiltrados] = useState([]);
    const [selected, setSelected] = useState([]);
    const [estatisticas, setEstatisticas] = useState({
        quantidade: 0,
        total: 0,
        maior: 0,
        menor: 0,
        mediaMensal: 0
    });


    const columns = [
        { id: 'amount', label: 'Valor', filterable: true, render: (row) => formatCurrency(row.amount) },
        { id: 'usuario.nome', label: 'Nome', filterable: true, render: (row) => row.usuario?.nome || '---' },
        { id: 'status', label: 'Status', filterable: true },
        { id: 'created_at', label: 'Data', filterable: true },
        { id: 'pix_code', label: 'Chave Pix', filterable: true },
    ];


    useEffect(() => {
        async function getPixPaymentsAndAccredited() {
            //se houver filtros
            // queryData = {
            //     startDate: dateFilter.startDate,
            //     endDate: dateFilter.endDate,
            //   };
            const accrediteds = await Api.getAccrediteds();
            const { data } = await Api.getPixPayments();
            console.log(data)

            data.map(item => {
                if (item.credenciado) {
                    console.log(item)
                }
            })
            const selectItems = accrediteds.data.map(item => {
                return {
                    label: item.nome_fantasia,
                    value: item._id
                }
            })
            console.log(selectItems)
            setCredenciados(selectItems)
            setTransacoes(data)
            setDadosFiltrados(data);
            calcularEstatisticas(data)
        }
        getPixPaymentsAndAccredited()
        // const dadosFicticios = [
        //     { _id: 1, valor: 150, data: '2025-02-01', status: 'Concluído', credenciado: 'Loja A', chavePix: 'chave1' },
        //     { _id: 2, valor: 250, data: '2025-02-05', status: 'Concluído', credenciado: 'Loja B', chavePix: 'chave2' },
        //     { _id: 3, valor: 50, data: '2025-02-10', status: 'Concluído', credenciado: 'Loja A', chavePix: 'chave1' },
        // ];
        // setTransacoes(dadosFicticios);
    }, []);


    const calcularEstatisticas = (dados) => {
        if (dados.length === 0) return;

        const valores = dados.map(t => t.amount);
        const total = valores.reduce((sum, val) => sum + val, 0);
        const maior = Math.max(...valores);
        const menor = Math.min(...valores);
        const quantidade = dados.length;
        const meses = new Set(dados.map(t => moment(t.payed_at).format('YYYY-MM'))).size;
        const mediaMensal = meses > 0 ? total / meses : 0;

        setEstatisticas({ quantidade, total, maior, menor, mediaMensal });
    };

    const filtrarDados = (data) => {
        let filtrados = transacoes.filter(transacao => {
            return (
                (!data.filtroDataInicio || moment(transacao.data).isSameOrAfter(data.filtroDataInicio, 'day')) &&
                (!data.filtroDataFim || moment(transacao.data).isSameOrBefore(data.filtroDataFim, 'day')) &&
                (data.filtroCredenciado.length === 0 || data.filtroCredenciado.includes(transacao.credenciado?._id)) &&
                (!data.filtroChavePix || transacao.chavePix.includes(data.filtroChavePix))
            );
        });
        setDadosFiltrados(filtrados);
        calcularEstatisticas(filtrados);
    };

    const exportToExcel = () => {
        if (dadosFiltrados.length === 0) {
            alert("Nenhum dado para exportar.");
            return;
        }

        // Mapeia os dados para um formato amigável
        const dataToExport = dadosFiltrados.map(transacao => ({
            "ID": transacao._id,
            "Usuário": transacao.usuario?.nome || "N/A",
            "Valor": formatCurrency(transacao.amount),
            "Status": transacao.status,
            "Data": transacao.created_at,
            "Credenciado": transacao.credenciado?.nome_fantasia,
            "Chave PIX": transacao.pix_code,
        }));

        // Cria um worksheet e um workbook
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório PIX");

        // Gera o arquivo e inicia o download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

        saveAs(blob, `relatorio_pix_${moment().format("YYYYMMDD_HHmmss")}.xlsx`);
    };


    return (
        <Container>
            <Card content={
                <>
                    <Button
                        onClick={() => goTo("Menu")}
                        style={{ backgroundColor: "#008000", color: "#FFF" }}
                        bsStyle="success"
                        simple={true}
                        type="button"
                        bsSize="sm"
                    >
                        {" "}
                        <i className="fa fa-bars" aria-hidden="true" /> Menu
                    </Button>
                    <br />
                    <br />
                    <br />
                    <form onSubmit={handleSubmit(filtrarDados)}>
                        <Row>
                            <Col md={2}>
                                <FormGroup>
                                    <ControlLabel>Período Inicial</ControlLabel>
                                    <Controller
                                        name="filtroDataInicio"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                value={field.value ? moment(field.value).format("YYYY-MM-DD") : ""}
                                                onChange={(val) => {
                                                    field.onChange(val ? moment(val).toDate() : null);
                                                }}
                                                dateFormat="DD/MM/YYYY"
                                                className="form-control"
                                            />
                                        )}
                                    />

                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <ControlLabel>Período Final</ControlLabel>
                                    <Controller
                                        name="filtroDataFim"
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                value={field.value ? moment(field.value).format("YYYY-MM-DD") : ""}
                                                onChange={(val) => {
                                                    field.onChange(val ? new Date(val) : null);
                                                }}
                                                dateFormat="DD/MM/YYYY"
                                                className="form-control"
                                            />
                                        )}
                                    />




                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginTop: '3.5px' }}>
                                    <ControlLabel>Credenciado</ControlLabel>
                                    <Controller
                                        name="filtroCredenciado"
                                        control={control}
                                        render={({ field }) => (
                                            <MultiSelect
                                                style={{
                                                    height: 40,
                                                    border: "0.5px solid rgba(0, 0, 0, 0.2) !important",
                                                    width: "100%",
                                                    color: "gray",
                                                }}
                                                options={credenciados}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col md={6}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'initial' }}>
                                    <div>
                                        <h4>Estatísticas</h4>
                                        <p>Quantidade de PIX: {estatisticas.quantidade}</p>
                                        <p>Valor Total Recebido: {formatCurrency(estatisticas.total)}</p>
                                        <p>Maior PIX: {formatCurrency(estatisticas.maior)}</p>
                                        <p>Menor PIX: {formatCurrency(estatisticas.menor)}</p>
                                        <p>Média de PIX por Usuário Mensalmente: {formatCurrency(estatisticas.mediaMensal)}</p>
                                    </div>

                                </div>
                            </Col> */}
                        </Row>
                        <Button type="submit" variant="contained" color="primary">Filtrar</Button>
                        <Button type="button" variant="outlined" color="primary" style={{ marginLeft: '0.5rem' }} onClick={exportToExcel}>Exportar</Button>
                    </form>
                </>
            } />
            <CustomTable columns={columns} data={dadosFiltrados} selected={selected} setSelected={setSelected} />
        </Container>
    );
};

export default PixReport;
