export const CompareStrings = function(a, b) {
  return String(a).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(b).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
};

export const NormalizeString = function(s) {
  return String(s).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}

export const OnlyNumbers = function(s) {
  return String(s).replace(/[^0-9]/g, '')
}