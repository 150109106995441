import React from 'react';
import * as S from './Styles';

export default function CustomButton({ content, ...props }) {
  return (
    <S.CustomButton {...props} readOnly={true}>
      {content}
    </S.CustomButton>
  );
}
