import React from "react";
import ReactTable from "react-table";
import Capitalize from 'capitalize-pt-br';
import Loading from '../Components/Loading';
import open from "../../../assets/img/open.png";
import MyAPI from "../../../connections/Api.jsx";
import closed from "../../../assets/img/closed.png";
import image from "../../../assets/img/carrinho.png";
import Card from "../../../components/Card/Card.jsx";
import impressora from "../../../assets/img/print.png";
import cancel from "../../../assets/img/icon_cancel.png";
import Notifications from "../Components/Toast/Toast.jsx";

import { makeStyles } from "@material-ui/core/styles";
import { printer } from "../../../utils/Relatorios.js";
import { ToastProvider } from "react-toast-notifications";
import { Grid, Row, Col, FormControl, Image, Button, FormGroup } from "react-bootstrap";

export default function Reverse() {
  const [temp, setTemp] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [dados, setDados] = React.useState([]);
  const [paswd, setPswd] = React.useState(null);
  const [vendas, setVendas] = React.useState(null);
  const [locked, setLocked] = React.useState(true);
  const [opeImg, setOpeImg] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [fakePassword, setFakePassword] = React.useState([]);

  const classes = useStyles();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await MyAPI.getAccredited();

        if (!response.data.error) {
          setDados(response.data);
          setPswd(response.data[0].cnpj_cpf < 2 ? 1234 : `${response.data[0].cnpj_cpf}`.slice(0, 4));

          const credenciado = await MyAPI.getHistoricAccredited(response.data[0]._id);

          setVendas(credenciado.data.filter((item) => item.confirmcao_pagamento !== null).filter((item) => item.situacao !== "Cancelado").filter((item) => item.parcela_atual === 1).reverse());
        }
      } catch (e) {
        setError([{ message: "Não foi possível buscar os seus dados de vendas!", appearance: "error", auto_dismiss: true }]);
      }
    }
    fetchData();
  }, [update]);

  async function buyCancel(Codigo) {
    if (window.confirm(`Deseja realmente canecelar a venda ${Codigo}?`)) {
      const { data } = await MyAPI.getReverse(dados[0]._id, Codigo, dados[0].nome_fantasia);

      if (data.error) {
        setError([{ message: data.message, appearance: "error", auto_dismiss: true }]);
      } else {
        setError([{ message: "Venda cancelada com sucesso!", appearance: "success", auto_dismiss: true }]);
        setUpdate(!update);
      }
    }
  }

  const islocked = (password) => {
    const pass = password.split("").filter((e) => e !== "*" && e !== "").join("");

    if (temp.length <= password.split("").length) {
      setTemp([...temp, pass]);
    } else {
      temp.pop();
      setTemp(temp);
    }

    setFakePassword(password.replace(`${password}`, `${password.split("").map(() => "*").join("")}`));

    const encoded = Number([...temp, pass].join("").replace(/\D+/g, ""));

    if (encoded === Number(paswd)) {
      setOpeImg(true);
      setTimeout(() => setLocked(false), 700);
    } else {
      return true;
    }
  };

  if (vendas === null) { return <Loading /> }

  return (
    <div className="content" id="print">
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              id="cardSimples"
              title="Estornos"
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  {locked ? (
                    <div
                      className={{
                        height: window.innerHeight * 0.8,
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${!opeImg ? closed : open})`,
                          height: "80px",
                          width: "80px",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          margin: "auto",
                          marginTop: "17%",
                        }}
                      />
                      <div className={classes.notification}>
                        Senha Administrativa
                      </div>
                      <Row>
                        <Col md={5}></Col>
                        <Col md={2}>
                          <FormGroup controlId="formControlsTextarea">
                            <FormControl
                              type="text"
                              variant="outlined"
                              disabled={opeImg}
                              value={fakePassword}
                              autoFocus={true}
                              placeholder=" * * * * "
                              style={{ borderColor: "#000000", marginBottom: "90%", marginTop: "10px" }}
                              onChange={(event) => islocked(event.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={5}></Col>
                      </Row>
                    </div>
                  ) : vendas.length <= 0 ? (
                    <div align="center" style={{ height: window.innerHeight * 0.5 }}>
                      <div className={classes.background} />
                      <div className={classes.notification2}>
                        Ops! Não existem guias a serem estornadas
                      </div>
                    </div>
                  ) : (
                    <div align="center" style={{ margin: 15 }}>
                      <ReactTable
                        data={vendas.map((prop, key) => {
                          return {
                            id: key,
                            canal: prop.canal,
                            data_hora: prop.horario_confirmacao,
                            empresa: `${String(prop.empresa_responsavel_id === undefined ? "-" : String(prop.empresa_responsavel_id.nome_fantasia).slice(0, 25))}`,
                            cliente: `${Capitalize(String(prop.usuario_id === undefined ? "-" : String(prop.usuario_id.nome).slice(0, 28)))}`,
                            valor: `R$ ${prop.valor.toFixed(2).replace(".", ",")}`,
                            protocolo: prop._id,
                            acoes: (
                              <div className="actions-center">
                                <Button
                                  onClick={() => { buyCancel(prop._id) }}
                                  variant="contained"
                                  bsStyle="info"
                                  style={{
                                    border: 0,
                                    color: "white",
                                    borderRadius: 3,
                                    marginRight: 10,
                                    fontSize: "14px",
                                    background: "rgba(255,0,0, .7)",
                                    boxShadow:
                                      "0 1px 1px 1px rgba(0, 0, 0, .2)",
                                  }}
                                >
                                  <Image src={cancel} style={{ width: "20px", height: "20px" }}></Image>
                                </Button>
                                <Button
                                  onClick={() => {
                                    printer(
                                      prop._id,
                                      prop.canal,
                                      prop.horario_confirmacao,
                                      prop.valor,
                                      prop.credenciado_id.nome_fantasia,
                                      "Ramicard",
                                      prop.empresa_responsavel_id.nome_fantasia,
                                      prop.usuario_id.nome,
                                      prop.parcelas,
                                      prop.observacao,
                                      false,
                                      false,
                                      prop.taxa_funcionario
                                    );
                                  }}
                                  variant="contained"
                                  bsStyle="info"
                                  style={{
                                    border: 0,
                                    color: "white",
                                    borderRadius: 3,
                                    fontSize: "14px",
                                    background: "rgba(35,142,35, .8)",
                                    boxShadow:
                                      "0 1px 1px 1px rgba(0, 0, 0, .2)",
                                  }}
                                >
                                  <Image src={impressora} style={{ width: "20px", height: "20px" }}></Image>
                                </Button>
                              </div>
                            ),
                          };
                        })}
                        filterable
                        columns={[
                          {
                            Header: "CANAL",
                            accessor: "canal",
                          },
                          {
                            Header: "DATA & HORA",
                            accessor: "data_hora",
                          },
                          {
                            Header: "EMPRESA",
                            accessor: "empresa",
                          },
                          {
                            Header: "CLIENTE",
                            accessor: "cliente",
                          },
                          {
                            Header: "VALOR",
                            accessor: "valor",
                          },
                          {
                            Header: "Nº DE PROTOCOLO",
                            accessor: "protocolo",
                          },
                          {
                            Header: "",
                            accessor: "acoes",
                            filterable: false,
                            sortable: false,
                          },
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight text-center"
                      />
                    </div>
                  )}
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${image})`,
    height: "80px",
    width: "80px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    margin: "auto",
    marginTop: "17%",
  },

  notification: {
    flex: 1,
    fontSize: 20,
    marginTop: "20px",
    textAlign: "center",
  },

  notification2: {
    flex: 1,
    fontSize: 20,
    marginTop: "20px",
    marginBottom: "200px",
    textAlign: "center",
  },

  notification3: {
    flex: 1,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "30px",
    textAlign: "center",
  },
});
